import Styled from 'styled-components'

export default Styled.div`
    box-shadow: 0px 3px 32px #272D3B33;
    border-radius: 20px;
    background: #fff;
    padding-bottom: 1em;
    h1 {
        padding: 0.5em 1.5em;
        padding-top: 1.5em;
        font-weight: 700;
        font-size: 1.1rem;
        color: ${({ theme }) => theme.primary};
    }
    div.subscription-lists {
        padding-left: 1.5em;
        max-height: 20rem;
        overflow-y: auto;
        div.sub-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.5em 2em;
            border-bottom: 1px solid #E6E7E8;
            div.profile-container {
                display: flex;
                align-items: center;
                div.img-container {
                    margin-right: 0.5em;
                    width: 2rem;
                    height: 2rem;
                    background: ${({ theme }) => theme.primary};
                    border-radius: 50%;
                }
                div {
                    h3 {
                        font-size: 1rem;
                        line-height: 100%;
                        font-weight: 600;
                        color: ${({ theme }) => theme.primary};
                    }
                    p {
                        font-size: 0.8rem;
                         color: #A2ABB9;
                    }
                }
            }
            button {
                font-size: 0.7rem;
            }
        }
        h1 {
            font-size: 1rem;
            font-weight: 600;
            color: black;
        }
    }
`
