import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";
import { IoArrowBack } from "react-icons/io5";
import { RiArrowRightSLine, RiErrorWarningLine } from "react-icons/ri";
import { dashboardRoute } from "../../../constants";
import { TableContainer } from "../../../UI";
import {
  banUsers,
  unbanUsers,
  searchUsers,
  getUser,
  checkInUsers,
  markPurchaseAsReceived,
  getUserPurshaseHistory,
  altLoadingState,
} from "../../../redux/actions";
import Container from "./style";
import BasicInfo from "./BasicInfo";
import Subscription from "./Subscription";
import SlotLists from "./SlotLists";
import ReservedClass from "./ReservedClass";
import ReservedSlots from "./ReservedSlots";
import PalletContent from "./PalletContent";
import EditUserModal from "../ManageUser/ModalContent";
import { columns } from "./tableData";
import DebitUserModal from "./DebitUserModal";

const UserProfile = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { params } = useRouteMatch();
  const userId = params[dashboardRoute.admin.userProfile.params];
  const prevId = useRef(null);
  const [showModal, setDisplay] = useState(false);
  const [activeUser, setActiveUser] = useState({});
  const [receivedLoading, setReceiveLoading] = useState([]);
  const [purchaseHistory, setPurchaseHistory] = useState(null);
  const { gymLocations } = useSelector((s) => s.dashStats);
  const dispatch = useDispatch();

  useEffect(() => {
    getUserPurshaseHistory(userId).then((response) => {
      setPurchaseHistory(response);
    });
  }, [userId]);

  const handleQueryFetch = ({ page, search } = {}) => {
    let queries = [];

    if (page) {
      queries.push(`page=${page}`);
    }

    if (search) {
      queries.push(`inventory_name=${search}`);
    }

    setPurchaseHistory(null);
    getUserPurshaseHistory(
      userId,
      queries.length > 0 ? `?${queries.join("&")}` : null
    ).then((res) => {
      setPurchaseHistory(res);
    });
  };

  useEffect(() => {
    if (userId && prevId.current !== userId) {
      dispatch(altLoadingState(true));
      if (userId) {
        searchUsers(userId).then((response) => {
          setActiveUser(response.data);
          dispatch(altLoadingState(false));
        });
      } else {
        prevId.current = userId;
        getUser(userId).then((response) => {
          dispatch(altLoadingState(false));
          if (response) {
            setActiveUser(response.data);
          } else {
            setActiveUser("");
          }
        });
      }
    }
  }, [params, prevId, dispatch, userId]);

  const handleAltBan = async (action) => {
    try {
      setLoading(true);
      action === "ban"
        ? await dispatch(banUsers(activeUser.id, activeUser.name))
        : await dispatch(unbanUsers(activeUser.id, activeUser.name));
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckInUser = async (index) => {
    const today = new Date();
    const time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    try {
      setLoading("checkIn");
      await dispatch(checkInUsers({ user_id: activeUser.id, time: time }));
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showModal === "debitUser" && (
        <DebitUserModal
          {...{ activeUser, handleModalClose: () => setDisplay(null) }}
        />
      )}
      {showModal === "editUser" && (
        <EditUserModal
          {...{ activeUser, handleModalClose: () => setDisplay(null) }}
        />
      )}
      {showModal === "slotLists" && (
        <SlotLists
          {...{
            ...activeUser,
            handleCheckInUser,
            handleClose: () => setDisplay(false),
          }}
        />
      )}
      <Container>
        <header>
          <div className="header-row">
            <button
              type="button"
              aria-label="back"
              onClick={() => {
                history.goBack();
              }}
            >
              <IoArrowBack />
            </button>
            <h1 className="u-typo-title">Profile Page</h1>
          </div>
          <nav>
            <NavLink to={dashboardRoute.admin.user}>Manage User</NavLink>
            <RiArrowRightSLine />
            <NavLink
              to={`${dashboardRoute.admin.userProfile.link}/${
                params[dashboardRoute.admin.userProfile.params]
              }`}
            >
              Profile Page
            </NavLink>
          </nav>
        </header>

        {activeUser ? (
          <PalletContent
            {...{
              activeUser,
              handleAltBan,
              handleEditUser: () => setDisplay("editUser"),
              handleManualDebitClick: () => setDisplay("debitUser"),
              handleCheckInUser,
              loading,
            }}
          />
        ) : null}

        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="User Details" key="1">
            {activeUser ? (
              <>
                <div className="profile-grid">
                  {activeUser && (
                    <>
                      <BasicInfo {...{ activeUser }} />
                      <Subscription {...{ ...activeUser }} />
                      <ReservedSlots {...{ ...activeUser }} />
                      <ReservedClass {...{ ...activeUser }} />
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className="dontExist-container">
                <RiErrorWarningLine />
                <h1>User with the id of {userId} doesn't exist</h1>
              </div>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Purchase History" key="2">
            <TableContainer
              {...{
                columns: columns({
                  receivedLoading,
                  gymLocations,
                  markItemAsReceived: (row) => {
                    setReceiveLoading((s) => [...s, row.id]);
                    dispatch(markPurchaseAsReceived(row.id)).finally(() => {
                      handleQueryFetch({ page: purchaseHistory?.current_page });
                      setReceiveLoading((s) => s.filter((i) => i !== row.id));
                    });
                  },
                }),
                searchHandler: (searchVal) => {
                  handleQueryFetch({ search: searchVal });
                },

                dataSource: purchaseHistory?.data,
                loading: !purchaseHistory?.data,
                pagination: {
                  pageSize: purchaseHistory?.per_page,
                  current: purchaseHistory?.current_page,
                  total: purchaseHistory?.total,
                  onChange: (page) => {
                    handleQueryFetch({ page });
                  },
                },
              }}
            />
          </Tabs.TabPane>
        </Tabs>
      </Container>
    </>
  );
};

export default UserProfile;
