import React from 'react'
import Container from './style'

const Subscription = ({ subscriptions, name, reserved_classes }) => {
  return (
    <Container>
      <h1>Reserved Class</h1>
      <hr />
      <div className="subscription-lists">
        {reserved_classes?.data && reserved_classes.data.length > 0 ? (
          reserved_classes.data.map(({ name, details }) => (
            <div className="sub-item">
              <div className="profile-container">
                <div>
                  <h3>{name}</h3>
                  <p>{details}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h1 className="empty-placeholder">
            {name} don't have any subscription for now
          </h1>
        )}
      </div>
    </Container>
  )
}

export default Subscription
