import Styled from "styled-components";
import { maxQuery } from "../../../helpers";

export default Styled.div`
padding-bottom: 3em;
header {
    margin-bottom: 1.5em;

    div.header-row {
        display: flex;
        align-items: center;
        grid-gap: 1em;
        button {
            background: transparent;
            padding: 0px;
            display: flex;
            align-items: center;
            border: none;
            .icon {
                color: ${({ theme }) => theme.primary};
                font-size: 1.6rem;
            }
        }
    }
    h1 {
        color: ${({ theme }) => theme.primary};
        font-size: 1.2rem;
        font-weight: 600;
    }
    nav {
        display: flex;
        margin-top: 0.5em;
        align-items: center;
        .icon {
            font-size: 1.2rem;
        }
        a {
            color: #A8A8A8;
            font-weight: 500;
            user-select: none;
            font-size: 0.8rem;
            &:hover, &:active, &:focus {
                text-decoration: underline;
                color: ${({ theme }) => theme.primary};
            }
            &.active {
                color: ${({ theme }) => theme.primary};
            }
        }
    }
}
div.profile-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.5em;
    ${maxQuery("md")} {
        grid-template-columns: 1fr;

    }
}
div.dontExist-container {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 4em;
    .icon {
        font-size: 5rem;
        display: inline-block;
        margin: auto;
        margin-bottom: 0.1em;
    }
    h1 {
        font-size: 1rem;
        max-width: 20rem;
        margin: auto;
    }
}
`;
