import Styled from 'styled-components'

export default Styled.div`
    div.modal-container {
        width: 38rem;
        padding-bottom: 2em;
        div.component-container {
            height: 60vh;
            max-height: 40rem;
            ${({ theme }) => theme.mixins.scrollbar};
            padding: 1.5em;
            font-size: 1rem;
            div.user-lists {
                p.label-item {
                    margin-top: 1em;
                    margin-bottom: 0.8em;
                    color: ${({ theme }) => theme.primary};
                }
            }
            div.emptyUserLists-container {
                height: 8rem;
                margin-top: 4em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .icon {
                    font-size: 4rem;
                    display: block;
                }
                p {
                    margin-top: 1em;
                    font-size: 1rem;
                }
            }
        }
    }
`
