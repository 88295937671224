import Styled from 'styled-components'

export default Styled.div`
width: 100vw;
height: 100vh;
position: fixed;
top: 0px;
left: 0px;
z-index: 999;
display: flex;
align-items: center;
justify-content: center;
-webkit-backdrop-filter: blur(0.7px);
background: rgba(71, 64, 78, 0.6);
/* Blurred-Overlay */
backdrop-filter: blur(2px);
img {
    width: 10rem;
    height: 10rem;
}


`
