/** @flow */
import { message } from "antd";

const handleError = (error) => {
  let errorMessage = "";
  if (error) {
    const { data } = error;
    if (data.details) {
      errorMessage = data.details;
    } else if (typeof data !== "object") {
      errorMessage = "An error occurred, please try again";
    } else if (data.errors) {
      for (let error of Object.values(data.errors)) {
        errorMessage = `${errorMessage}${
          error !== null || error !== void 0 ? `${error}` : ""
        }`;
      }
    } else if (data.message) {
      errorMessage = data.message;
    }
  } else {
    errorMessage = "An error occurred, check your internet connection";
  }
  message.error(errorMessage, 8);
};

export default handleError;
