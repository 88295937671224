import Styled, { css } from 'styled-components'
import { rem, rgba } from 'polished'
import { maxQuery } from '../../helpers'

export default Styled.div`
        width: 100%;
        ${({ display }) =>
          display &&
          css`
            &:after {
              content: '';
              position: absolute;
              width: 100vw;
              height: 100vh;
              z-index: 10;
              top: 0px;
              left: 0px;
              backdrop-filter: blur(2px);
              -webkit-backdrop-filter: blur(0.7px);
              background: rgba(71, 64, 78, 0.6);
            }
          `}
        form {
        ${maxQuery('md')} {
            display: none;
        }
        position: relative;
        min-height: ${rem('36px')};
        height: 100%;
        width: 100%;
        max-width: 40rem;
        button.search-btn {
            position: absolute;
            z-index: 45;
            background: transparent;
            border: none;
            top: 50%;
            margin-left: 1.25em;
            transform: translateY(-50%);
            &.search-btn {
                margin-left: 1em; 
            }
            &.close-btn {
                right: 1em;
            }
            .icon {
                font-size: 1.2rem;
                color: #666666;
            }
        }
        input {
            width: 100%;
            border-radius: 50px;
            outline: none;
            position: relative;
            z-index: 44;
            border: 1.3px solid rgba(38, 38, 38, 0.3);
            min-height: ${rem('36px')};
            height: 100%;
            padding: 0px 3em;
            padding-right: 0px;
            &:hover, &:active, &:focus {
                border: 1.3px solid ${({ theme }) => theme.primary};
            }
            &::placeholder {
                color: #aeaeae;
                font-size: ${rem('14px')};
                letter-spacing: 0.14px;
                font-weight: 300;
            }
        }
        div.userlist-container {
            position: absolute;
            width: 100%;
            max-width: 40rem;
            box-shadow: 0px 0px 2px #00000029;
            border-radius: 10px;
            background: #fff;
            height: 20rem;
            z-index: 999;
            padding: 1em;
            ${({ theme }) => theme.mixins.scrollbar};
            button {
                font-size: 1rem;
                padding: 0px 1em;
                cursor: pointer;
                background: transparent;
                width: 100%;
                border: none;
                font-size: 1rem;
                text-align: left;
                padding: 1em;
                &:hover, &:focus {
                    background: ${({ theme }) => rgba(theme.primary, 0.1)}
                }
            }
            /* div.search-cover {
                position: fixed;
                width: 100vw;
                height: 100vh;
                background: blue;
                z-index: -1;
                top: 0px;
                left: 0px;
            } */
        }
    }
`
