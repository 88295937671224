import React from 'react'
import { Button } from '../../../../UI'
import Container from './style'

const Subscription = ({ subscriptions, name }) => {
  return (
    <Container>
      <h1>Subscription</h1>
      <hr />
      <div className="subscription-lists">
        {subscriptions?.data && subscriptions.data.length > 0 ? (
          subscriptions.data.map(({ plan, start_date, end_date, status }) => (
            <div className="sub-item">
              <div className="profile-container">
                <div className="img-container" />
                <div>
                  <h3>
                    {plan.name}({plan.interval})
                  </h3>
                  <p>
                    {start_date} - {end_date}
                  </p>
                </div>
              </div>
              <Button secondary small>
                {status}
              </Button>
            </div>
          ))
        ) : (
          <h1 className="empty-placeholder">
            {name} don't have any subscription for now
          </h1>
        )}
      </div>
    </Container>
  )
}

export default Subscription
