import Styled from 'styled-components'

export default Styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    header.component-header {
        margin-bottom: 1.5em;
        h1 { 
            font-size: 1.1rem;
            font-weight: 600;
            color: ${({ theme }) => theme.primary};
        }
    }
    div.subAdmin-table_header {
        display: flex;
        align-items: center;
        grid-gap: 2em;
        h1 {
            font-size: 1.1rem;
        }
    }
`
