import Styled from 'styled-components'

export default Styled.div`
    form {
        max-width: 90%;
        padding-top: 4em;
        margin: 2em auto;
        height: 20rem;
    }

`
