import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Container from "./style";
import { dashboardRoute } from "../../../constants";
import { fetchDashboardData } from "../../../redux/actions";
import { TableContainer } from "../../../UI";
import { columns } from "./tableData";
import PalletContent from "./PalletContent";

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { top_customers_by_checkins, ...rest } = useSelector(
    (state) => state.dashStats.dashStats
  );

  useEffect(() => {
    dispatch(fetchDashboardData());
  }, [dispatch]);

  return (
    <Container>
      <PalletContent {...{ ...rest }} />
      <TableContainer
        {...{
          columns: columns({
            handleViewProfile: (row) => {
              history.push(
                `${dashboardRoute.admin.userProfile.link}/${row.id}`
              );
            },
          }),
          dataSource: top_customers_by_checkins?.data,
        }}
      />
    </Container>
  );
};

export default Home;
