export const columns = ({ handleViewProfile }) => [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a, b) => a.name - b.name,
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    sorter: (a, b) => a.email - b.email,
    key: 'email',
  },
  {
    title: 'User Status',
    dataIndex: 'status',
    sorter: (a, b) => a.status - b.status,
    key: 'status',
  },
  {
    title: 'Phone No',
    dataIndex: 'phone',
    key: 'phone',
  },
]
