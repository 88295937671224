import React from 'react'
import moment from 'moment'
import { Spinner } from '../../../../UI'
// import { checkInUsers } from '../../../../redux/actions'
import { Modal } from '../../../../UI'
import Container from './style'

const SlotLists = ({
  reserved_slots,
  name,
  id,
  handleClose,
  handleCheckInUser,
  loading,
}) => {
  return (
    <Container>
      <Modal
        showModal={true}
        modalTitle={`${name} Slots`}
        onClose={handleClose}
      >
        <header>
          <h3>Click on a slot below to checkin</h3>
        </header>
        <div className="slot-content">
          <div className="slotlist-container">
            {reserved_slots &&
              reserved_slots.data &&
              reserved_slots.data.map(({ name, time, end_time }, index) => (
                <div
                  className="slotlists-item"
                  onClick={() => handleCheckInUser(time, index)}
                >
                  <div className="col-1">
                    <h3>Slot - {name}</h3>
                    <p>
                      {moment(time, 'HH:mm:ss').format('hh:mm A')} -{' '}
                      {moment(end_time, 'HH:mm:ss').format('hh:mm A')}
                    </p>
                  </div>
                  <div className="col-2">
                    {loading === index && <Spinner />}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </Container>
  )
}

export default SlotLists
