import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { Skeleton } from "antd";
import { GoCloudUpload } from "react-icons/go";
import { formValidator } from "../../../../helpers";
import { Modal, Button, InputGroup } from "../../../../UI";
import { AppConstants } from "../../../../constants";
import { useQuery } from "../../../../hooks";
import { altTrainer, getAllTrainers } from "../../../../redux/actions";
import Container from "./style";

const ModalContent = (props = {}) => {
  const { activeTrainer, handleModalClose } = props;
  const { trainers, gymLocations } = useSelector((state) => state.dashStats);
  const queryTrainerId = useQuery().get("trainerId");
  const trainerId = activeTrainer?.id || queryTrainerId;
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(
    trainerId
      ? null
      : {
          name: "",
          trainer_fee: "",
          // email: "",
          // phone: "",
          gender: "m",
          image_data: "",
          location_id: 1,
        }
  );

  const handleAddImg = ({ target: { files } }) => {
    if (files[0].size > 2000000) {
      message.info("Image size is too large", 8);
    } else {
      setFormData((s) => ({ ...s, image_data: files[0] }));
    }
  };

  console.log({ formData, trainers }, "sjdksdsjkdj");

  useEffect(() => {
    if (trainerId) {
      const { trainer_details, location_id } =
        trainers.data.find((d) => d.id === Number(trainerId)) || {};

      setFormData({
        ...trainer_details,
        location_id,
      });
    }
  }, [trainerId, trainers]);

  const handleInput = ({ target: { name, value } }) => {
    setFormData((s) => ({ ...s, [name]: value }));
  };

  const handleSubmit = async () => {
    if (
      formValidator([
        ...document.forms["subadmin-form"].getElementsByTagName("input"),
        ...document.forms["subadmin-form"].getElementsByTagName("select"),
      ])
    ) {
      try {
        setLoading(true);

        const payload = {
          ...formData,
        };

        if (trainerId) {
          payload.id = trainerId;
        }

        // role_id = 3 is for subAdmin
        const response = await dispatch(altTrainer({ ...payload }));
        if (response.status === "success") {
          history.goBack();
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <Container>
      <Modal
        showModal={true}
        onClose={() =>
          handleModalClose ? handleModalClose() : history.goBack()
        }
        modalTitle={trainerId ? "Edit Trainer" : "New Trainer"}
        modalFooter={
          Boolean(formData) && (
            <Button form="subadmin-form" full loading={loading} type="submit">
              {trainerId ? "Edit Trainer" : "Create Trainer"}
            </Button>
          )
        }
      >
        {formData ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            id="subadmin-form"
            name="subadmin-form"
            noValidate
          >
            <InputGroup
              label="Name"
              required={true}
              name="name"
              value={formData.name}
              onChange={handleInput}
            />
            <InputGroup
              label="Trainer Fee"
              required={true}
              type="number"
              name="trainer_fee"
              value={formData.trainer_fee}
              onChange={handleInput}
            />
            <InputGroup
              type="select"
              label="Select Gender"
              name="gender"
              required={true}
              value={formData.gender}
              onChange={handleInput}
              optionLists={
                <>
                  <option value="" disabled>
                    Select Gender...
                  </option>
                  {["m", "f"].map((gender) => (
                    <option value={gender} key={gender}>
                      {gender === "m" ? "Male" : "Female"}
                    </option>
                  ))}
                </>
              }
            />
            {/* <InputGroup
              label="Email"
              required={true}
              name="email"
              value={formData.email}
              onChange={handleInput}
            />
            <InputGroup
              label="Phone Number"
              name="phone"
              type="tel"
              required={true}
              value={formData.phone}
              onChange={handleInput}
            /> */}
            <InputGroup>
              <label>Location</label>
              <select
                name="location_id"
                required={true}
                onChange={handleInput}
                value={formData.location_id}
              >
                {Object.keys(gymLocations).map((locationId) => (
                  <option value={locationId}>{gymLocations[locationId]}</option>
                ))}
              </select>
            </InputGroup>
            {console.log({ formData }, "formData")}
            <div className="img-container">
              <label
                style={{
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundImage:
                    formData.image_data && !Array.isArray(formData.image_data)
                      ? `url(${
                          typeof formData.image_data === "string"
                            ? `${AppConstants.imgBase}${formData.image_data}`
                            : URL.createObjectURL(formData.image_data)
                        })`
                      : "",
                }}
              >
                <div>
                  <GoCloudUpload />
                  <p>Max file size 2MB</p>
                  <p>Upload Trainer profile picture</p>
                  <input
                    type="file"
                    id="avatar"
                    onChange={handleAddImg}
                    name="avatar"
                    accept="image/png, image/jpeg"
                  />
                </div>
              </label>
            </div>
          </form>
        ) : (
          <div className="loading-container">
            <Skeleton />
            <Skeleton />
          </div>
        )}
      </Modal>
    </Container>
  );
};

export default ModalContent;
