const AppConstants = {
  imgBase: "https://api.ecofitnesshub.com/",
};

export const LOCATIONS = Object.freeze({
  2: "Abuja",
  1: "Lagos",
});

export const RECEIVED = Object.freeze({
  1: "Received",
  0: "Not-received",
});

export default AppConstants;
