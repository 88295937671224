import Styled from 'styled-components'
import { maxQuery } from '../../helpers'

export default Styled.div`
    display: grid;
    height: 100vh;
    width: 100vw;
    left: 0px;
    top: 0px;
    position: fixed;
    grid-template-columns: 1fr 1fr;
    ${maxQuery('lg')} {
        grid-template-columns: 1fr;
    }
    div.col-1 {
        display: flex;
        ${maxQuery('lg')} {
            display: none;
        }
        align-items: center;
        justify-content: center;
        background: ${({ theme }) => theme.primary};
        img {
            width: 70%;
        }
    }
    div.col-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #F3F7F5;
        padding-bottom: 10%;
        header {
            width: 80%;
            max-width: 40rem;
            margin-bottom: 2em;
            img {
                width: 7rem;
                margin: 0 auto;
                margin-bottom: 10%;
                display: block;
            }
            h3 {
                font-size: 1.2rem;
            }
            h1 {
                font-weight: 700;
                font-size: 1.5rem;
            }
        }
        form {
            width: 80%;
            max-width: 40rem;
            button.submit-btn {
                margin-top: 6em;
            }
            div.link-container {
                display: flex;
                justify-content: flex-end;
                a {
                    color: ${({ theme }) => theme.primary};
                    font-weight: 600;
                    font-size: 1rem;
                    &:hover, &:focus {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
`
