import Styled from "styled-components";
import { minQuery, maxQuery } from "../../../../helpers";
import { fitness } from "../../../../asset/images";

export default Styled.div`
    margin-bottom: 2.5em;
    box-shadow: 0px 3px 32px #272D3B33;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    z-index: 5;
    header {
        height: 15rem;
        position: relative;
        background-image: url(${fitness});
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        ${maxQuery("<xl")} {
            padding-top: 1.5em;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }

        button.edit-btn {
            position: absolute;
            right: 0px;
            display: flex;
            align-items: center;
            grid-gap: 0.35em;
            .icon {
                font-size: 1.1rem;
            }
        }
        h1 {
            ${minQuery("xl")} {
                position: absolute;
                top: 7.5em;
                left: 14.5em;
            }
            font-weight: 600;
            color: #fff!important;
            font-size: 1.5rem;
        }
        div.img-container {
            z-index: 2;
            width: 12rem;
            height: 12rem;
            border: 5px solid #fff;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-color: ${({ theme }) => theme.primary};
            ${minQuery("xl")} {
                position: absolute;
                top: 6em;
                left: 6em;

            }
        }
    }
    footer {
        background: #fff;
        position: relative;
        z-index: 1;
        margin-bottom: 1.5em;
        div.action-group {
            ${minQuery("xl")} {
                margin-left: 22em;
            }
            ${maxQuery("xl")} {
                margin-top: 1.5em;
                padding-left: 2em;
            }
            display: flex;
            grid-gap: 1em;
            flex-wrap: wrap;
            button {
                padding: 0.5em 2em;
                font-size: 0.8rem;

                &:first-of-type {
                    margin-right: 2em;
                    &.ban-section {
                        color: #FF5E5E!important;
                        border-color: #FF5E5E!important;
                        div.spinner {
                            border-top: 4px solid #FF5E5E!important;
                        }
                    }
                    &.unban-section {

                    }
                }

            }
        }
    }

`;
