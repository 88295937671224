import { message } from "antd";
import { axios, handleError } from "../../lib";

export const broadCastNotification = async (payload) => {
  try {
    let url = `/admin/users/mass_mail`;

    const { responseCode, ...response } = await axios.post(url, payload);
    message.success("Successfully sent broadcast", 8);

    return response.data;
  } catch ({ response }) {
    handleError(response);
  }
};
