export const isEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  )
}

const formValidator = (inputs) => {
  let validated = true
  Array.from(inputs).map((input) => {
    const { type, value, required } = input
    const inputId = input.getAttribute('data-errorid')
    const errorDom = inputId ? document.getElementById(inputId) : null
    const isPassword = input.getAttribute('data-type') === 'password'

    const name = input.getAttribute('data-label')
      ? input.getAttribute('data-label')
      : input.name
    if (required && !isPassword && errorDom) {
      if (value) {
        errorDom.innerHTML = ''
      } else {
        validated = false
        errorDom.innerHTML = `${name} cannot be blank`
      }
    }

    if (type === 'email' && errorDom) {
      if (isEmail(value)) {
        errorDom.innerHTML = ''
      } else {
        validated = false
        errorDom.innerHTML = 'Invalid Email Address'
      }
    }

    if ((type === 'password' || isPassword) && errorDom && required) {
      if (value) {
        if (value.length < 5) {
          validated = false
          errorDom.innerHTML = `Your password must be greater than 5 characters`
        } else {
          errorDom.innerHTML = ''
        }
      } else {
        validated = false
        errorDom.innerHTML = `Password cannot be blank`
      }
    }

    return input
  })
  return validated
}

export default formValidator
