import Styled from 'styled-components'

export default Styled.div`    
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    div.financial-header {
        display: flex;
        align-items: center;
        grid-gap: 2em;
        h1 {
            font-size: 1.1rem;
        }
    }

`
