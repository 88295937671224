import { axios, handleError } from "../../lib";
import { message } from "antd";

export const altSubAdmins = (data, actionType) => ({
  type: "ALT_SUBADMINS",
  data,
  actionType,
});

export const getAllSubAdmins = () => async (dispatch) => {
  try {
    const response = await axios.get("/admin/users/all?type=sub_admin");
    if (response.data) {
      dispatch(altSubAdmins(response.data));
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const createSubAdmin = (data) => async (dispatch) => {
  try {
    const response = await axios.post("/admin/users/create-sub-admin", data);
    if (response.status === "success") {
      await dispatch(getAllSubAdmins(data));
      message.success(response.details, 8);
      return {
        status: true,
      };
    }
  } catch ({ response }) {
    handleError(response);
  }
};
