import Styled from 'styled-components'

export default Styled.div`
    div.modal-container {
        width: 38rem;
        padding-bottom: 2em;
        div.component-container {
            height: 60vh;
            max-height: 30rem;
            ${({ theme }) => theme.mixins.scrollbar};
            padding: 1.5em;
            font-size: 1rem;
        }
        footer {
            padding: 1em 2em;
            button {
                padding: 1em;
            }
        }
    }
`
