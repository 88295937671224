import React from "react";
import { Button } from "../../../UI";

export const columns = ({ handleViewProfile }) => [
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => a.name - b.name,
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: (a, b) => a.email - b.email,
    key: "email",
  },
  {
    title: "Phone No",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Total CheckIns",
    dataIndex: "total_check_ins",
    sorter: (a, b) => a.total_check_ins - b.total_check_ins,
    key: "total_check_ins",
    align: "center",
  },
  {
    title: "Action",
    align: "center",
    render: (row) => (
      <Button
        secondary
        className="table-btn"
        onClick={() => handleViewProfile(row)}
      >
        View Profile
      </Button>
    ),
  },
];
