import React from 'react'
import moment from 'moment'
import { Timeline } from 'antd'
import { Modal } from '../../../../UI'
import Container from './style'

const SlotModal = ({ class_slots, name }) => {
  return (
    <Container>
      <Modal showModal={true} modalTitle={`${name} Slots`}>
        <h3>Lists of slots for {name} class and their time</h3>
        <div className="slots-lists">
          <Timeline>
            {class_slots.data.map((item) => (
              <Timeline.Item key={item.name}>
                <p>Slot {item.name}</p>
                <p>
                  {moment(item.time, 'HH:mm:ss').format('hh:mm A')} -{' '}
                  {moment(item.end_time, 'HH:mm:ss').format('hh:mm A')}
                </p>
              </Timeline.Item>
            ))}
          </Timeline>
        </div>
      </Modal>
    </Container>
  )
}

export default SlotModal
