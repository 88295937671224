import moment from "moment";

export const columns = ({ gymLocations }) => [
  {
    title: "Name",
    key: "name",
  },
  {
    title: "Email",
    sorter: (a, b) => a?.inventory.item - b?.inventory.item,
    render: (d) => d?.inventory?.item,
  },
  {
    title: "Location",
    align: "center",
    sorter: (a, b) => a.location.id - b.location.id,
    render: (a) => gymLocations[Number(a.location.id)],
  },
  {
    title: "Date",
    align: "center",
    render: (a) => moment(a.created_at).format("MMMM Do YYYY, h:mm:ss a"),
  },
];
