import React from 'react'
import moment from 'moment'
import { IoMdMore } from 'react-icons/io'
import { Button } from '../../../UI'

export const columns = ({
  handleEditSlot,
  actionHandler,
  handleDeleteSlots,
  activeIndex,
}) => [
  {
    title: 'Name',
    sorter: (a, b) => a.name - b.name,
    render: (row) => (
      <div className="profile-container">
        <div className="img-container" />
        {row.name}
      </div>
    ),
    key: 'name',
  },
  {
    title: 'Time',
    render: (row) => {
      return (
        row.end_time && (
          <span>
            {moment(row.time, 'HH:mm:ss').format('hh:mm A')} -{' '}
            {moment(row.end_time, 'HH:mm:ss').format('hh:mm A')}
          </span>
        )
      )
    },
  },
  {
    title: 'Action',
    render: (row, detail, index) => (
      <div className="action-container">
        <div className="table-btn">
          <Button
            icon
            onClick={(e) => {
              e.stopPropagation()
              actionHandler(row)
            }}
          >
            <IoMdMore />
          </Button>
        </div>
        {row.id === activeIndex && (
          <div className="context-menu">
            <Button tertiary full onClick={() => handleEditSlot(row)}>
              Edit
            </Button>
            {/* <Button
              tertiary
              full
              className="btn-delete"
              onClick={() => handleDeleteSlots(row)}
            >
              Delete
            </Button> */}
          </div>
        )}
      </div>
    ),
  },
]
