import React, { useState, useEffect } from "react";
import { Table } from "antd";
import debounce from "lodash.debounce";
import { GrFormAdd } from "react-icons/gr";
import { CSVLink } from "react-csv";
import { IoClose } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import { BiExport } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { Button, InputGroup } from "../../UI";
import Container from "./styles";

const TableContainer = ({
  children,
  dataSource,
  columns,
  middleContent,
  className,
  customHeader,
  title,
  searchPlaceHolder = "Search table",
  hasSearch = true,
  hasAdd = false,
  addAction,
  searchHandler,
  ...props
}) => {
  const [tableData, setTableData] = useState(dataSource ? dataSource : []);
  const [searchInput, setsearchInput] = useState("");
  const [showSearch, setDisplay] = useState(false);

  const handleInput = ({ target: { value } }) => {
    setsearchInput(value);
  };

  const delayedFetchAlgoliaData = debounce((props) => {
    searchHandler(props);
  }, 300);

  useEffect(() => {
    if (searchInput && searchHandler) {
      delayedFetchAlgoliaData(searchInput);
    }

    return delayedFetchAlgoliaData.cancel;
  }, [searchInput]);

  useEffect(() => {
    setTableData(dataSource);
  }, [dataSource]);

  useEffect(() => {
    if (dataSource && hasSearch && !searchHandler) {
      if (searchInput) {
        setTableData(() =>
          dataSource.filter(
            (item) =>
              (item.name &&
                item.name.toLowerCase().includes(searchInput.toLowerCase())) ||
              (item.email && item.email.includes(searchInput)) ||
              (item.phone && item.phone.includes(searchInput))
          )
        );
      } else {
        setTableData(dataSource);
      }
    }
  }, [dataSource, searchInput, searchHandler, hasSearch]);

  return (
    <Container className={`table-cover ${className}`}>
      <header>
        {customHeader ? (
          customHeader
        ) : (
          <>
            <div className="table-col__1">
              <h1 className="table-title">{title}</h1>
            </div>
            <div className="center-content">
              {middleContent && middleContent()}
              {hasAdd && (
                <Button
                  onClick={() => {
                    if (typeof addAction === "function") {
                      addAction();
                    }
                  }}
                >
                  <GrFormAdd />
                  Add
                </Button>
              )}
            </div>
          </>
        )}
        <div className="table-col__2">
          {showSearch && hasSearch ? (
            <div className="table-search">
              <Button
                icon
                arial-label="close search"
                className="back-btn"
                onClick={() => {
                  searchHandler("");
                  setDisplay(false);
                  setsearchInput("");
                }}
              >
                <IoIosArrowBack />
              </Button>
              <InputGroup
                value={searchInput}
                placeholder={searchPlaceHolder}
                onChange={handleInput}
              />
              {searchInput && (
                <Button
                  icon
                  className="close-btn"
                  arial-label="clear search"
                  onClick={() => {
                    setsearchInput("");
                    searchHandler("");
                  }}
                >
                  <IoClose />
                </Button>
              )}
            </div>
          ) : (
            <div className="action-group">
              <CSVLink data={dataSource ? dataSource : []}>
                <Button icon>
                  <BiExport />
                  Export
                </Button>
              </CSVLink>
              {hasSearch && (
                <Button icon onClick={() => setDisplay(true)}>
                  <FiSearch />
                  Search
                </Button>
              )}
            </div>
          )}
        </div>
      </header>
      <div className="table-container">
        {children ? (
          children
        ) : (
          <Table
            {...{
              ...props,
              dataSource: tableData,
              columns,
              loading: !tableData || props.loading,
              pagination: props.pagination || null,
            }}
          />
        )}
      </div>
    </Container>
  );
};

export default TableContainer;
