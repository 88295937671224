import React from 'react'
import { dashboardRoute } from '../../../../constants'
import { useHistory } from 'react-router-dom'
import { FiArrowUpRight } from 'react-icons/fi'
import { Users, User } from '../../../../asset/convertedSvgs'
import Container from './style'

const PalletContent = ({ total_users, total_revenue, total_classes }) => {
  const history = useHistory()
  return (
    <Container>
      <h2 className="title-txt">Dashboard</h2>
      <div className="pallet-container">
        <div
          className="pallet-item total-user"
          role="button"
          onClick={() => history.push(dashboardRoute.admin.user)}
        >
          <User />
          <h1>{total_users}</h1>
          <p>Total Users</p>
          <div className="arrow-right">
            <FiArrowUpRight />
          </div>
        </div>
        <div
          className="pallet-item revenue-item"
          role="button"
          onClick={() => history.push(dashboardRoute.admin.report)}
        >
          {/* <h1>N {toMoney(total_revenue)}</h1>
          <p>Total Revenue</p>
          <div className="graph-container">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div> */}
        </div>
        <div
          className="pallet-item"
          role="button"
          onClick={() => history.push(dashboardRoute.admin.classes)}
        >
          <Users />
          <h1>{total_classes}</h1>
          <p>Total Classes</p>
        </div>
      </div>
    </Container>
  )
}

export default PalletContent
