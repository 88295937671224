import Styled from 'styled-components'

export default Styled.div`
text-align: center;
label {
    height: 8rem;
    border-radius: 5px;
    padding-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    border: 1px dotted ${({ theme }) => theme.primary};
    
    svg {
        font-size: 2.5rem;
        margin-bottom: 0.25em;
    }
    p {
        font-size: 0.9rem;
        margin: 0px;
    }
    input {
        visibility: hidden;
    }
}
div.img-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0px;
    grid-gap: 1.2em;
    padding: 0.5em;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.primary}; 
    div.img-container {
        background-size: cover;
        background-position: center center;
        border: 0.5px solid ${({ theme }) => theme.primary};
        width: 2rem;
        flex-shrink: 0;
        height: 2rem;
    }
    p {
        font-size: 0.9rem;
        &.img-name {
            width: 100%;
            text-align: left;
            ${({ theme }) => theme.mixins.lineClamp(1)}
        }
    }
    div.spinner {
        margin-top: 0.25em;
    }
}

`
