import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { useQuery } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { TableContainer, Button } from "../../../UI";
import { getAllTrainers, deleteTrainer } from "../../../redux/actions";
import { dashboardRoute } from "../../../constants";
import { columns } from "./tableData";
import ModalContent from "./ModalContent";
import Container from "./style";

const initFilterState = {
  trainerType: "",
  gender: "",
  location_id: "",
};

const ManageTrainer = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const modal = useQuery().get("modal");
  const [apiLoading, setApiLoading] = useState([]);

  const [{ trainerType, location_id, gender }, setFilterState] =
    useState(initFilterState);

  const { trainers, gymLocations } = useSelector((state) => state.dashStats);
  const history = useHistory();
  const [{ activeIndex, loading, currentPage }, setState] = useState({
    activeIndex: null,
    loading: false,
    currentPage: 1,
  });

  useEffect(() => {
    if (loading && currentPage !== trainers.current_page) {
      setState((s) => ({
        ...s,
        loading: false,
        currentPage: trainers.current_page,
      }));
    }
  }, [trainers, loading, currentPage]);

  const handleQueryFetch = ({ page, search } = {}) => {
    let queries = [];

    if (trainerType) {
      queries.push(`status=${trainerType}`);
    }

    if (gender) {
      queries.push(`gender=${gender}`);
    }

    if (location_id !== "") {
      queries.push(`location_id=${location_id}`);
    }

    if (page) {
      queries.push(`page=${page}`);
    }

    if (search) {
      queries.push(`search=${search}`);
    }

    setState((s) => ({
      ...s,
      loading: true,
    }));

    dispatch(
      getAllTrainers(queries.length > 0 ? `?${queries.join("&")}` : null)
    ).finally(() => {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    });
  };

  const handleDeleteTrainer = async (row) => {
    setApiLoading((s) => [...s, row.id]);
    try {
      await dispatch(deleteTrainer(row.id));
    } finally {
      setApiLoading((s) => s.filter((d) => d !== row.id));
    }
  };

  useEffect(() => {
    handleQueryFetch();
  }, [trainerType, location_id, gender]);

  const actionHandler = (index) => {
    setState((s) => ({ ...s, activeIndex: index }));
  };

  const clickHandler = useCallback(() => {
    if (activeIndex || activeIndex === 0) {
      setState((s) => ({ ...s, activeIndex: null }));
    }
  }, [activeIndex]);

  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  });

  const handleInput = ({ target: { name, value } }) => {
    setFilterState((s) => ({
      ...s,
      [name]: value,
    }));
  };

  const isActive = trainerType || gender || location_id;

  return (
    <Container>
      {modal === "addTrainer" && <ModalContent {...{ action: modal }} />}
      <header className="component-header">
        <h1>All Trainers</h1>
        <div className="header-row">
          <div />
          <div className="lastCol">
            <Button
              onClick={() => history.push(`${pathname}?modal=addTrainer`)}
            >
              <IoMdAdd />
              Add New
            </Button>
          </div>
        </div>
      </header>
      {console.log({ trainers }, "sdjskjsdkj")}
      <TableContainer
        {...{
          columns: columns({
            gymLocations,
            handleViewProfile: (row) => {
              history.push(
                `${dashboardRoute.admin.trainerProfile.link}/${row.id}`
              );
            },
            handleEditProfile: (row) => {
              history.push(
                `${dashboardRoute.admin.trainerProfile.link}?modal=addTrainer&trainerId=${row.id}`
              );
            },
            handleDeleteTrainer: (row) => {
              handleDeleteTrainer(row);
            },
            activeIndex,
            actionHandler,
            viewHandler: ({ id }) => {
              history.push(`/admin/dashboard/creators/${id}/details`);
            },
            apiLoading,
          }),
          searchHandler: (searchVal) => {
            handleQueryFetch({ search: searchVal });
          },

          dataSource: trainers.data,
          activeIndex,
          loading: loading,
          pagination: {
            pageSize: trainers?.per_page,
            current: trainers?.current_page,
            total: trainers?.total,
            onChange: (page) => {
              handleQueryFetch({ page });
            },
          },
        }}
      />
      {/* </Tabs.TabPane> */}
      {/* <Tabs.TabPane tab="Orders" key="2">
          <header className="component-header">
            <h1>All Orders</h1>
          </header>
          <TableContainer
            {...{
              columns: orderColumns({
                handleViewProfile: (row) => {
                  history.push(
                    `${dashboardRoute.admin.trainerProfile.link}/${row.id}`
                  );
                },
                handleViewOrder: (row) => {
                  history.push(
                    `${dashboardRoute.admin.trainerProfile.link}/${row.id}/orders`
                  );
                },
                activeIndex,
                actionHandler,
                viewHandler: ({ id }) => {
                  history.push(`/admin/dashboard/creators/${id}/details`);
                },
              }),
              searchHandler: (searchVal) => {
                handleQueryFetch({ search: searchVal });
              },

              dataSource: trainers.data,
              activeIndex,
              loading: loading,
              pagination: {
                pageSize: trainers?.per_page,
                current: trainers?.current_page,
                total: trainers?.total,
                onChange: (page) => {
                  handleQueryFetch({ page });
                },
              },
            }}
          />
        </Tabs.TabPane>
      </Tabs> */}
    </Container>
  );
};

export default ManageTrainer;
