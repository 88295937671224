import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";
import { formValidator } from "../../../../helpers";
import { Modal, Button, InputGroup } from "../../../../UI";
import { useQuery } from "../../../../hooks";
import { createSubAdmin, getUser } from "../../../../redux/actions";
import Container from "./style";

const ModalContent = (props = {}) => {
  const { activeUser, handleModalClose } = props;
  const queryUserId = useQuery().get("userId");
  const userId = activeUser?.id || queryUserId;
  const dispatch = useDispatch();
  const history = useHistory();
  const { gymLocations } = useSelector((s) => s.dashStats);

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(
    userId
      ? null
      : {
          name: "",
          email: "",
          phone: "",
          gender: "m",
          location_id: 1,
        }
  );

  useEffect(() => {
    if (userId)
      getUser(userId).then((res) => {
        if (res.data) {
          const { gender, email, name, phone, location_id } = res.data;

          setFormData({
            gender,
            email,
            name,
            phone,
            location_id,
          });
        }
      });
  }, [userId]);

  const handleInput = ({ target: { name, value } }) => {
    setFormData((s) => ({ ...s, [name]: value }));
  };

  const handleSubmit = async () => {
    if (
      formValidator([
        ...document.forms["subadmin-form"].getElementsByTagName("input"),
        ...document.forms["subadmin-form"].getElementsByTagName("select"),
      ])
    ) {
      try {
        setLoading(true);

        const payload = {
          ...formData,
        };

        if (userId) {
          payload.id = userId;
        }

        const action = dispatch(createSubAdmin({ ...payload, role_id: 2 }));

        // role_id = 3 is for subAdmin
        const response = await action;
        if (response && response.status === true) {
          history.goBack();
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <Container>
      <Modal
        showModal={true}
        onClose={() =>
          handleModalClose ? handleModalClose() : history.goBack()
        }
        modalTitle={userId ? "Edit User" : "New User"}
        modalFooter={
          Boolean(formData) && (
            <Button form="subadmin-form" full loading={loading} type="submit">
              {userId ? "Edit User" : "Create User"}
            </Button>
          )
        }
      >
        {formData ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            id="subadmin-form"
            name="subadmin-form"
            noValidate
          >
            <InputGroup
              label="Name"
              required={true}
              name="name"
              value={formData.name}
              onChange={handleInput}
            />
            <InputGroup
              type="select"
              label="Select Gender"
              name="gender"
              required={true}
              value={formData.gender}
              onChange={handleInput}
              optionLists={
                <>
                  <option value="" disabled>
                    Select Gender...
                  </option>
                  {["m", "f"].map((gender) => (
                    <option value={gender} key={gender}>
                      {gender === "m" ? "Male" : "Female"}
                    </option>
                  ))}
                </>
              }
            />
            <InputGroup
              label="Email"
              required={true}
              name="email"
              value={formData.email}
              onChange={handleInput}
            />
            <InputGroup
              label="Phone Number"
              name="phone"
              type="tel"
              required={true}
              value={formData.phone}
              onChange={handleInput}
            />
            <InputGroup>
              <label>Location</label>
              <select
                name="location_id"
                required={true}
                onChange={handleInput}
                value={formData.location_id}
              >
                {Object.keys(gymLocations).map((locationId) => (
                  <option value={locationId}>{gymLocations[locationId]}</option>
                ))}
              </select>
            </InputGroup>
          </form>
        ) : (
          <div className="loading-container">
            <Skeleton />
            <Skeleton />
          </div>
        )}
      </Modal>
    </Container>
  );
};

export default ModalContent;
