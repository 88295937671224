import Styled, { keyframes } from 'styled-components'
import { rem } from 'polished'
import { maxQuery } from '../../helpers'

const slideIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`
export default Styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    /* animation: ${slideIn} 0.2s ease-in-out; */
    top: 0px;
    left: 0px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-backdrop-filter: blur(0.7px);
    background: rgba(71, 64, 78, 0.6);
    /* Blurred-Overlay */
    backdrop-filter: blur(2px);


    div.modal-container {        
        background: #FFFFFF;        
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 19px;
        width: ${rem('720px')};
        max-width: 90%;
        position: relative;
        button.close-btn {
            position: absolute;
            top: 0.45em;
            right: 0.3em;
            z-index: 1000;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.1em;
            font-size: 1.5rem;
            color: ${({ theme }) => theme.primary};
            ${maxQuery('md')} {
                position: fixed; 
            }
        }
        header.modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 2em;
            h2.modal-title {
                padding: 0.8em 0px;
                font-weight: 600;
                color: ${({ theme }) => theme.primary};
                font-size: 1.1rem;
            }
            button.close-btn {
                 position: relative;
                 top: inherit;
                 right: -0.5em;
                 .icon {
                     color: #4D4476;
                 }
             }
            border-bottom: 1px solid #E5E5E5;
        }
        footer.modal-footer {     
            border-top: 1px solid #E5E5E5;
            padding: 1.5em;
        }
    }
    div.modal-close__relative button.close-btn {
            top: 1.2em;
            right: 1.2em;
            position: absolute;
    }
    div.modal-size__sm {        
        width: ${rem('373px')};
    }
    div.modal-size__md {        
        width: ${rem('811px')};
    }
`
