import React, { useEffect, useState, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IoMdAdd } from 'react-icons/io'
import { Button, TableContainer } from '../../../UI'
import { useQuery } from '../../../hooks'
import { getAllSlots } from '../../../redux/actions'
import { columns } from './tableData'
import ModalContent from './ModalContent'
import Container from './style'

const ManageSlot = () => {
  const { slots } = useSelector((state) => state.dashStats)
  const [activeIndex, setActiveIndex] = useState(null)

  const dispatch = useDispatch()
  const modal = useQuery().get('modal')
  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    dispatch(getAllSlots())
  }, [dispatch])

  const clickHandler = useCallback(() => {
    if (activeIndex || activeIndex === 0) {
      setActiveIndex(null)
    }
  }, [activeIndex])

  useEffect(() => {
    document.addEventListener('click', clickHandler)
    return () => {
      document.removeEventListener('click', clickHandler)
    }
  })

  return (
    <Container>
      {modal && <ModalContent {...{ action: modal, slots }} />}
      <header className="component-header">
        <h1>Slots</h1>
      </header>
      <TableContainer
        {...{
          dataSource: slots,
          middleContent: () => (
            <Button onClick={() => history.push(`${pathname}?modal=add`)}>
              <IoMdAdd />
              Add New
            </Button>
          ),
          columns: columns({
            handleEditSlot: (row) => {
              history.push(`${pathname}?modal=edit`, {
                id: row.id,
              })
              setActiveIndex(null)
            },
            actionHandler: (row) => {
              setActiveIndex(row.id)
            },
            activeIndex,
          }),
        }}
      />
    </Container>
  )
}

export default ManageSlot
