import Styled from 'styled-components'
import { maxQuery } from '../../../../helpers'

export default Styled.div`
    h2.title-txt {
        font-size: 1.1rem;
        font-weight: 600;
        color: ${({ theme }) => theme.primary};
        margin-bottom: 1.25em;
    }
    div.pallet-container {
        display: grid;
        grid-gap: 2em;
        margin-bottom: 2.5em;
        grid-template-columns: repeat(3, 1fr);
        ${maxQuery('md')} {
            grid-template-columns: 1fr;
        }
        div.pallet-item {
            border-radius: 10px;
            padding: 1em 1.5em;
            position: relative;
            background: ${({ theme }) => theme.primary};
            color: #fff;
            p {
                font-size: 0.9rem;
            }
            div.first-pallet {
                padding-top: 3em;
                h1 {
                    font-size: 2.1rem;
                    font-weight: 800;
                    color: #fff;
                }
            }
            div.bottom-pallet {
                right: 1em;
                display: flex;
                margin-top: -1em;
                align-items: center;
                justify-content: flex-end;
                div.arrow-right {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #fff;
                    height: 2.5rem;
                    margin-left: 1em;
                    width: 2.5rem;
                    color: ${({ theme }) => theme.primary};
                    border-radius: 50%;
                    .icon {
                        font-size: 1.7rem;
                    }
                }

            }
        }
        div.revenue-item {
            background: #F6FBE6;
            h1, p {
                color: ${({ theme }) => theme.primary}!important;
            }
            div.graph-container {
                height: 5rem;
                font-size: 0.8rem;
            }
            div.arrow-right {
                background-color: ${({ theme }) => theme.primary}!important;
                .icon {
                    color: #fff;
                }
            }
        }
    }

`
