import Styled from "styled-components";
import { minQuery, maxQuery } from "../../../helpers";
import {
  leavebottombottom,
  leavebottomleft,
  leaverigthbottom,
} from "../../../asset/images";

export default Styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  height: calc(100vh - var(-vh-offset, 0px));
  position: fixed;
  top: 0px;
  left: 0px;
  display: grid;
  ${minQuery(">lg")} {
    grid-template-columns: 19rem 1fr;
  }
  aside.admin-sideNav {
      ${maxQuery("lg")} {
        position: fixed;
        z-index: 88;
        height: 100vh;
        height: calc(100vh - var(-vh-offset, 0px));
        transition: all 0.5s ease-in-out;
        width: 100vw;
        transform: translateX(-100%);
        &.slide-in {
            transform: translateX(0%);
        }
        &.slide-out {
            transform: translateX(-100%);
        }
      }

  }
main {
    width: calc(100vw - 19rem);
    ${maxQuery("lg")} {
      width: 100vw;
    }
    div.admin-content__container {
        height: ${({ theme }) =>
          `calc(100vh - ${theme.dimensions.navHeight})`};   
        height: ${({ theme }) =>
          `calc(100vh - var(-vh-offset, 0px) - ${theme.dimensions.navHeight})`};
        background-image: url(${leavebottomleft}), url(${leavebottombottom}), url(${leaverigthbottom});
        background-position: -8% 120%, center 105%, 105% 140%;
        background-repeat: no-repeat, no-repeat, no-repeat;
        overflow-y: auto;
        ${({ theme }) => theme.mixins.scrollbar};
        div.admin__content {
            padding: 2em 2.5em;
            width: 90%;
            height: 100%;
            ${maxQuery("lg")} {
              width: 95%;
              padding: 2em 0px;
            }
            margin: 0 auto;  
        }
    }
}

`;
