import Styled from "styled-components";

export default Styled.div`
    padding-bottom: 10vh;
    header.component-header {
        margin-bottom: 1.5em;
        display: flex;
        grid-gap: 1em;
        flex-direction: column;

        h1 { 
            font-size: 1.1rem;
            font-weight: 600;
            color: ${({ theme }) => theme.primary};
        }

        div.header-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            div.action-group {
                display: flex;
                grid-gap: 2em;
                max-width: 45rem;
                div.input-group {
                    margin-bottom: 0px;
                }
                button {
                    flex-shrink: 0;
                    padding: 0px 1em;
                    display: block;
                    line-height: 100%;
                    height: 3rem;
                }
            }
        }
    }

    div.action-container {
        display: flex;
        justify-content: center;
    }
`;
