import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getAllClasses } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { TableContainer } from "../../../UI";
import { useQuery } from "../../../hooks";
import { columns } from "./tableData";
import ModalContent from "./ModalContent";
import SlotModal from "./SlotModal";
import Container from "./style";

const ManageClasses = () => {
  const modal = useQuery().get("modal");
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [activeClass, setActiveClass] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);

  const { classLists, slots, plans, gymLocations } = useSelector(
    (state) => state.dashStats
  );

  const handleEditClick = () => {
    history.push(`${pathname}?modal=edit`);
  };

  useEffect(() => {
    dispatch(getAllClasses());
  }, [dispatch]);

  const clickHandler = useCallback(() => {
    if (activeIndex || activeIndex === 0) {
      setActiveIndex(null);
    }
  }, [activeIndex]);

  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  });

  return (
    <>
      {modal === "slots" && <SlotModal {...{ ...activeClass }} />}
      {(modal === "add" || modal === "edit") && (
        <ModalContent {...{ action: modal, classLists, slots, plans }} />
      )}
      <Container>
        <header className="component-header">
          <h1>Classes</h1>
        </header>
        <TableContainer
          {...{
            hasSearch: false,
            hasAdd: true,
            addAction: () => history.push(`${pathname}?modal=add`),
            dataSource: classLists ? classLists : [],
            columns: columns({
              gymLocations,
              actionHandler: (row) => {
                setActiveIndex(row.id);
              },
              handleViewSlots: (row) => {
                setActiveClass(row);
                history.push(`${pathname}?modal=slots`);
              },
              handleEditClass: (row) =>
                history.push(`${pathname}?modal=edit`, {
                  id: row.id,
                }),
              activeIndex,
            }),
            classLists,
            handleEditClick,
            activeIndex,
          }}
        />
      </Container>
    </>
  );
};

export default ManageClasses;
