import * as React from 'react'

function SvgUsers(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={10} cy={10} r={2.5} stroke="#fff" />
      <path
        d="M15.058 10.317a2.5 2.5 0 10-1.038-3.843M15.5 13.5H19a1.5 1.5 0 011.5 1.5v1.5M4.5 19.5v-2a2 2 0 012-2h7a2 2 0 012 2v2"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgUsers
