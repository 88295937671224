import React, { useState } from 'react'
import { TimePicker } from 'antd'
import moment from 'moment'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { formValidator } from '../../../../helpers'
import { Modal, Button, InputGroup } from '../../../../UI'
import { updateSlots, createSlots } from '../../../../redux/actions'
import Container from './style'

const ModalContent = ({ action, slots }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const activeSlotData =
    location.state && location.state.id
      ? slots.find((item) => item.id === location.state.id)
      : {}
  const [loading, setLoading] = useState(false)

  const [formData, setFormState] = useState(() =>
    action === 'edit'
      ? {
          ...activeSlotData,
          time: [
            moment(activeSlotData.time, 'HH:mm:ss'),
            moment(activeSlotData.end_time, 'HH:mm:ss'),
          ],
        }
      : {
          name: '',
          time: [
            moment('12:08:23', 'HH:mm:ss'),
            moment('12:08:23', 'HH:mm:ss'),
          ],
          image: '',
        },
  )

  const handleInput = ({ target: { name, value } }) => {
    setFormState((s) => ({ ...s, [name]: value }))
  }

  const handleSubmit = async () => {
    if (
      formValidator(document.forms['slots-form'].getElementsByTagName('input'))
    ) {
      try {
        setLoading(true)
        const response =
          action === 'edit'
            ? await dispatch(updateSlots({ ...formData }))
            : await dispatch(createSlots({ ...formData }))
        if (response && response.success === true) {
          history.goBack()
        } else {
          setLoading(false)
        }
      } finally {
        setLoading(false)
      }
    }
  }
  return (
    <Container>
      <Modal
        showModal={true}
        modalTitle={action === 'edit' ? 'Edit Slot' : 'New Slot'}
        modalFooter={
          <Button form="slots-form" loading={loading} full type="submit">
            {action === 'edit' ? 'Save Slot' : 'Create Slot'}
          </Button>
        }
      >
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
          }}
          id="slots-form"
          name="slots-form"
          noValidate
        >
          <InputGroup
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInput}
            required={true}
          />
          <InputGroup className="time-input__container">
            <label>Time</label>
            <TimePicker.RangePicker
              name="time"
              selected={formData.time}
              required={true}
              onChange={(e, timeString, bb) => {
                setFormState((s) => ({
                  ...s,
                  time: [
                    moment(timeString[0], 'HH:mm:ss'),
                    moment(timeString[1], 'HH:mm:ss'),
                  ],
                }))
              }}
              value={formData.time}
            />
          </InputGroup>
        </form>
      </Modal>
    </Container>
  )
}

export default ModalContent
