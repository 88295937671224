import Styled from 'styled-components'

export default Styled.div`
div.modal-container {
    padding-bottom: 1em;
    height: 45rem;
    header {
        padding-left: 2em;
        margin-top: 1em;
        margin-bottom: 0.5em;
    }
    div.slot-content {
        padding-left: 1em;
        p {
            font-size: 1rem;
        }
        div.slotlist-container {
            max-height: 40rem;
            overflow-y: auto;
            div.slotlists-item {
                padding: 1em;
                cursor: pointer;
                margin: 0px 0.5em;
                display: grid;
                grid-template-columns: 1fr auto;
                align-items: center;
                &:not(:last-of-type) {
                    border-bottom: 1px solid #CED1E0;
                }
            }

        }
    }
}
    
`
