import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { BsClock } from "react-icons/bs";
import { useQuery } from "../../../../hooks";
import { RiAdminLine } from "react-icons/ri";
import { AiOutlineNotification } from "react-icons/ai";
import { MdOutlineInventory } from "react-icons/md";
import { GiStrongMan } from "react-icons/gi";
import { AiOutlineCreditCard } from "react-icons/ai";
import { HiQrcode } from "react-icons/hi";
import { ImStatsDots } from "react-icons/im";
import { House, Users, User, PieChart } from "../../../../asset/convertedSvgs";
import { AppLogo } from "../../../../asset/images";
import { dashboardRoute } from "../../../../constants";
import Container from "./styles";

const AdminSideNav = ({ role }) => {
  const { pathname } = useLocation();
  const modal = useQuery().get("modal");

  return (
    <Container>
      <header className="aside-header">
        <img src={AppLogo} alt="Eco Fitness" />
      </header>
      <div className="nav-container">
        <nav>
          <NavLink to={`${dashboardRoute.admin.home}`}>
            <House />
            Dashboard
          </NavLink>
          <NavLink to={`${dashboardRoute.admin.user}`}>
            <User />
            Manage User
          </NavLink>
          <NavLink to={`${dashboardRoute.admin.trainer}`}>
            <GiStrongMan />
            Manage Trainer
          </NavLink>
          <NavLink to={`${dashboardRoute.admin.notification}`}>
            <AiOutlineNotification />
            Notifications
          </NavLink>
          <NavLink to={`${dashboardRoute.admin.inventory}`}>
            <MdOutlineInventory />
            Manage Inventories
          </NavLink>
          {role === "admin" && (
            <NavLink to={`${dashboardRoute.admin.subAdmins}`}>
              <RiAdminLine />
              Sub Admins
            </NavLink>
          )}
          <NavLink
            to={{
              pathname: pathname,
              search: `?modal=${dashboardRoute.admin.qrUser.modalName}`,
            }}
            isActive={(match, location) => {
              return modal === dashboardRoute.admin.qrUser.modalName;
            }}
          >
            <HiQrcode />
            Qr Search
          </NavLink>
          <NavLink to={`${dashboardRoute.admin.classes}`}>
            <Users />
            Manage Classes
          </NavLink>
          <NavLink to={`${dashboardRoute.admin.plans}`}>
            <AiOutlineCreditCard />
            Manage Plans
          </NavLink>
          <NavLink to={`${dashboardRoute.admin.slots}`}>
            <BsClock />
            Manage Slots
          </NavLink>
          {role === "admin" && (
            <>
              <NavLink to={`${dashboardRoute.admin.report}`}>
                <ImStatsDots style={{ fontSize: "1.5em" }} />
                Reports
              </NavLink>
              <NavLink to={`${dashboardRoute.admin.financialReport}`}>
                <PieChart />
                Financial Report
              </NavLink>
            </>
          )}
        </nav>
      </div>
    </Container>
  );
};

export default AdminSideNav;
