import Styled from 'styled-components'

export default Styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    header.component-header {
        margin-bottom: 1.5em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 { 
            font-size: 1.12rem;
            color: ${({ theme }) => theme.primary};
        }
        button {
            display: flex;
            align-items: center;
            border-radius: 10px;
            .icon {
                color: #fff;
                margin-right: 0.5em;
                font-size: 1rem;
            }
        }
    }
    .ant-table.ant-table-middle .ant-table-title, .ant-table.ant-table-middle .ant-table-footer, .ant-table.ant-table-middle .ant-table-thead > tr > th, .ant-table.ant-table-middle .ant-table-tbody > tr > td, .ant-table.ant-table-middle tfoot > tr > th, .ant-table.ant-table-middle tfoot > tr > td:first-of-type {
        padding-left: 1em;
    }
`
