import Styled from 'styled-components'
import { maxQuery } from '../../../../helpers'
import { feeledLeave, emptyLeave } from '../../../../asset/images'

export default Styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: calc(100vh - var(-vh-offset, 0px));
    overflow: hidden;
    background-image: url(${feeledLeave}), url(${emptyLeave});
    background-position: -4em 86%, right bottom;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-color: ${({ theme }) => theme.primary};
    z-index: 999;
    ${maxQuery('lg')} {
        
      width: 19rem;
        * {
            position: relative;
            z-index: 888;
        }
        &::after {
            content: "";
            width: 100vw;
            position: absolute;
            height: 100%;
            -webkit-backdrop-filter: blur(0.7px);
            background: rgba(71, 64, 78, 0.6);            
            z-index: -1;
        }
    }
    header.aside-header {
        display: flex;
        justify-content: center;
        padding: 3em 0px;
        img {
            width: 7rem;
        }
    }
    div.nav-container {
        display: flex;
        margin-top: 1.5em;
        justify-content: space-between;
        flex-direction: column;
        flex: 1;
        height: 100%;
        position: relative;

        nav {
            background: ${({ theme }) => theme.primary};
            margin-top: 6%;
            a {
                display: flex;
                position: relative;
                align-items: center;
                font-size: 1rem;
                color: #fff;
                font-weight: 500;
                padding: 1em 1em;
                padding-left: 2em;
                svg {
                    font-size: 1.8rem;
                    margin-right: 0.5em;
                }
                &.active {
                    color: #94D500;
                    .icon {
                        color: #94D500;
                    }
                    svg {
                        * {
                            stroke: #94D500;
                        }
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        content: "";
                        width: 3.2px;
                        border-radius: 0px 5px 5px 0px;
                        height: 100%;
                        left: 0px;
                        background: #94D500;
                    }
                }
            }
        }
    }
`
