import mixins from "./mixins";

const dimensions = {
  navHeight: "4.5rem",
  navHeightMobile: "8rem",
  maxWidth: "1500px",
  adminSideNavWidth: "16rem",
  adminTopNavHeight: "4.5rem",
  footerHeight: "3.5rem",
};

export const BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const theme = () => ({
  primary: "#006633",
  tertiary: "#376536",
  bgColor: "#fff",
  mixins,
  dimensions,
  fontFamily: `
  'Poppins',-apple-system,BlinkMacSystemFont,"Helvetica Neue",
  "Segoe UI","Oxygen","Ubuntu","Cantarell","Open Sans",sans-serif
  `,
  secondary: "#BA390C",
});

export default theme;
