import styled, { css } from "styled-components";
import { lighten } from "polished";

export default styled.button`
  &:disabled {
    cursor: no-drop;
  }
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  span.loading {
    margin-left: 0.8em;
  }
  span.badge-container {
    position: absolute;
    background: #e93c3c;
    border-radius: 50%;
    padding: 0.25em;
    font-size: 0.7rem;
    top: -0.8em;
    right: -0.8em;
  }
  ${(props) => css`
    padding: 0.8em 1.8em;
    font-size: 0.9rem;
    border-radius: 4px;
    font-weight: 600;
    user-select: none;
    outline: none;
    background: transparent;
    width: ${props.full ? "100%" : "fit-content"};
    display: ${props.full ? "flex" : "inline-block"};
    ${props.primary &&
    css`
      border: 2px solid ${props.theme.primary};
      background-color: ${({ theme }) => theme.primary};
      color: #fff;
      box-shadow: 0px 4px 4px rgba(230, 234, 248, 0.25);
      &:hover,
      &:focus {
        background: ${({ theme }) => lighten(0.1, theme.primary)};
        border-color: ${({ theme }) => lighten(0.1, theme.primary)};
        color: #fff;
        outline: none;
      }
      &:disabled {
        background: ${({ theme }) => theme.disabled}!important;
        border-color: ${({ theme }) => theme.disabled}!important;
        color: #fff !important;
      }
    `}
    ${props.secondary &&
    css`
      border: 1px solid ${({ theme }) => theme.tertiary} !important;
      border-color: #fff;
      box-shadow: none;
      background: #fff;
      box-shadow: none;
      color: ${props.theme.tertiary};
      &:hover,
      &:focus {
        background: #fff;
        border-color: ${({ theme }) => lighten(0.1, theme.tertiary)}!important;
        color: ${({ theme }) => lighten(0.1, theme.tertiary)}!important;
        border: 1px solid ${({ theme }) => theme.tertiary} !important;
      }
      &:disabled {
        background: transparent !important;
        color: ${props.theme.disabled}!important;
      }
    `}
    ${props.tertiary &&
    css`
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      color: ${({ theme }) => theme.primary};
      &:hover,
      &:focus {
        border-color: transparent !important;
        background-color: transparent !important;
        color: ${({ theme }) => theme.primary}!important;
      }
    `}
    ${props.plain &&
    css`
      background-color: transparent;
      border-color: transparent !important;
      color: ${({ theme }) => theme.tertiary};
      padding: 0px;
      box-shadow: none;
      &:hover,
      &:focus {
        color: ${({ theme }) => lighten(0.1, theme.tertiary)}!important;
        border-color: transparent !important;
        background-color: transparent !important;
      }
    `}
    ${props.icon &&
    css`
      padding: 0px;
      background: transparent;
      box-shadow: none;
      border: none;
      .icon {
        font-size: 1.2em;
        margin: 0px;

        /* color: #cccccc; */
        padding: 0px;
      }
      &:hover,
      &:focus {
        background: transparent;
        border: none;
        outline: none;
      }
      &:disabled {
        background: transparent;
        border: none;
        color: #fff;
        outline: none;
      }
    `}
       ${props.loading &&
    css`
      display: inline-flex;
      align-items: center;
    `}
    &.icon-btn__hover {
      &:hover,
      &:focus {
        color: #fff;
        background-color: ${({ theme }) => lighten(0.1, theme.primary)};
      }
    }

    /* iconRight */
    ${props.iconRight &&
    css`
      display: flex;
      align-items: center;
      .icon {
        margin-right: 0.3em;
        font-size: 1.2em;
      }
    `}

    /* IconLeft */
    ${props.iconLeft &&
    css`
      display: flex;
      align-items: center;
      .icon {
        margin-left: 0.3em;
        font-size: 1.2em;
      }
    `}

     &.btn-money {
      padding: 0.2em 0.3em;
      background: #e2ffec;
      .icon {
        color: #47c479;
      }
    }
    &.btn-edit {
      padding: 0.2em 0.3em;
      background: #e2edff;
      border: 1px solid #e2edff;
      .icon {
        color: #4187ff;
      }
    }
    &.btn-delete {
      color: #ff5e5e;
      border: 1px solid #ff5e5e !important;
      .icon {
        color: #ff5e5e;
      }
      &:hover,
      &:focus {
        color: #ff5e5e !important;
      }
    }
    &.btn-copy {
      padding: 0.2em 0.3em;
      background: #f9f6fb;
      color: #855aaf;
      border: 1px solid #f9f6fb;
      .icon {
        color: #855aaf;
      }
    }
    /* notification */
    &.notification-badge {
      position: relative;
      &:after {
        content: "";
        top: -2px;
        right: 0%;
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #ff5e5e;
        border: 1.6px solid #fff;
      }
    }
  `}
`;
