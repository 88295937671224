import Styled from "styled-components";

export default Styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    header.component-header {
        margin-bottom: 1.5em;
        display: flex;
        grid-gap: 1em;
        flex-direction: column;

        h1 { 
            font-size: 1.1rem;
            font-weight: 600;
            color: ${({ theme }) => theme.primary};
        }

        div.action-group {
            display: flex;
            grid-gap: 2em;
            justify-content: space-between;

            div.col-1 {
                display: flex;
                width: 100%;
                grid-gap: 2em;
                max-width: 35rem;
            }
            div.input-group {
                margin-bottom: 0px;
            }
            button {
                flex-shrink: 0;
                padding: 0px 1em;
                display: block;
                line-height: 100%;
                height: 3rem;
            }
        }
    }
    .ant-table.ant-table-middle .ant-table-title, .ant-table.ant-table-middle .ant-table-footer, .ant-table.ant-table-middle .ant-table-thead > tr > th, .ant-table.ant-table-middle .ant-table-tbody > tr > td, .ant-table.ant-table-middle tfoot > tr > th, .ant-table.ant-table-middle tfoot > tr > td:first-of-type {
        padding-left: 1em;
    }
    button.delete-btn {
        color: red;
        &:hover, &:focus {
            color: red;
        }
    }
`;
