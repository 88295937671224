import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '../../../hooks'
import { getFinanceData } from '../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import Container from './style'
import { columns } from './tableData'
import { TableContainer, Button } from '../../../UI'
import ModalAddUsers from './ModalAddUsers'
import PalletContent from './PalletContent'

const FinancialReport = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const modal = useQuery().get('modal')
  const {
    financeData: { this_month, today, total, finance },
  } = useSelector((state) => state.dashStats)

  const { plans, users } = useSelector((state) => state.dashStats)

  useEffect(() => {
    dispatch(getFinanceData())
  }, [dispatch])

  return (
    <Container>
      {modal === 'addUser' && <ModalAddUsers {...{ plans, users }} />}
      <PalletContent {...{ this_month, today, total }} />
      <TableContainer
        {...{
          dataSource: finance?.data,
          pagination: { pageSize: 5 },
          customHeader: (
            <div className="financial-header">
              <h1>Finance</h1>
              <Button
                className="btn-sub"
                onClick={() => history.push('?modal=subscribeUser')}
              >
                Manual Subscribe
              </Button>
            </div>
          ),
          columns: columns({
            handleAddUser: (item) => {
              history.push('?modal=addUser', { financeData: item })
            },
          }),
        }}
      />
    </Container>
  )
}

export default FinancialReport
