import React from "react";
import { IoMdMore } from "react-icons/io";
import { Button, Spinner } from "../../../../UI";
import { toMoney } from "../../../../helpers";

export const columns = ({
  gymLocations,
  handleViewProfile,
  activeId,
  deleting,
  actionHandler,
  viewHandler,
  handleDelete,
  handleEditItem,
}) => [
  {
    title: "Item",
    dataIndex: "item",
    sorter: (a, b) => a.item - b.item,
    key: "item",
  },
  {
    title: "Price",
    dataIndex: "price",
    sorter: (a, b) => a.price - b.price,
    render: (d) => toMoney(d),
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    sorter: (a, b) => a.quantity - b.quantity,
    key: "quantity",
  },
  {
    title: "Location",
    render: (row) => gymLocations[Number(row.location_id)],
  },
  {
    title: "Actions",
    align: "center",
    render: (row) =>
      deleting.includes(row.id) ? (
        <Spinner />
      ) : (
        <div className="action-container">
          <div className="table-btn">
            <Button
              icon
              onClick={(e) => {
                e.stopPropagation();
                actionHandler(row);
              }}
            >
              <IoMdMore />
            </Button>
          </div>
          {row.id === activeId && (
            <div className="context-menu">
              <Button tertiary full onClick={handleEditItem}>
                Edit
              </Button>
              <Button
                tertiary
                full
                className="btn-delete"
                onClick={handleDelete}
              >
                Delete
              </Button>
            </div>
          )}
        </div>
      ),
  },
];
