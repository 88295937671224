import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { dashboardRoute } from "../../../constants";
import { formValidator } from "../../../helpers";
import { InputGroup, Button, Modal } from "../../../UI";
import Container from "./style";

const QRScreen = () => {
  const history = useHistory();
  const [searchVal, setFormState] = useState("");

  const handleInput = ({ target: { value, name } }) => {
    setFormState(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formValidator(document.forms["qr-form"].getElementsByTagName("input"))
    ) {
      history.push(`${dashboardRoute.admin.userProfile.link}/${searchVal}`);
    }
  };
  return (
    <Container>
      <Modal
        showModal={true}
        modalTitle="User Search"
        modalFooter={
          <Button full form="qr-form" type="submit">
            Search
          </Button>
        }
      >
        <form noValidate onSubmit={handleSubmit} id="qr-form">
          <InputGroup
            label="Search User"
            onChange={handleInput}
            placeholder="Userid"
            value={searchVal}
            required
          />
        </form>
      </Modal>
    </Container>
  );
};

export default QRScreen;
