import React from "react";
import { CSVLink } from "react-csv";
import { BiEdit } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { AppConstants } from "../../../../constants";
import { Button } from "../../../../UI";
import Container from "./style";

const PalletContent = ({
  activeUser,
  loading,
  handleManualDebitClick,
  handleEditUser,
  handleAltBan,
  handleCheckInUser,
}) => {
  const history = useHistory();
  return (
    <Container>
      <header>
        <div
          className="img-container"
          style={{
            backgroundImage: activeUser.image
              ? `url(${AppConstants.imgBase}${activeUser.image})`
              : "",
          }}
        />
        <h1>{activeUser.name}</h1>
        <Button className={"edit-btn"} onClick={handleEditUser}>
          Edit Profile <BiEdit />
        </Button>
      </header>
      <footer>
        <div className="action-group">
          <Button
            secondary
            className={
              activeUser.status === "active" ? "ban-section" : "unban-section"
            }
            loadWithLabel={false}
            disabled={loading}
            loading={loading !== "checkIn" && loading}
            onClick={() =>
              handleAltBan(activeUser.status === "active" ? "ban" : "unban")
            }
          >
            {activeUser.status === "active" ? "Ban User" : "Unban User"}
          </Button>
          <CSVLink data={activeUser ? [activeUser] : []}>
            <Button secondary>Export</Button>
          </CSVLink>
          <Button
            disabled={loading}
            loading={loading === "checkIn" && loading}
            onClick={() => {
              handleCheckInUser(activeUser);
            }}
          >
            Checkin User
          </Button>
          <Button secondary onClick={handleManualDebitClick}>
            Debit User
          </Button>{" "}
          <Button
            disabled={loading}
            loading={loading === "checkIn" && loading}
            onClick={() => {
              history.push("?modal=subscribeUser", { id: activeUser.id });
            }}
          >
            Manual Subscribe
          </Button>
        </div>
      </footer>
    </Container>
  );
};

export default PalletContent;
