import React, { useState } from 'react'
import { Select } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { formValidator } from '../../../../helpers'
import { Modal, Button, InputGroup } from '../../../../UI'
import { attachUserToSub } from '../../../../redux/actions'
import Container from './style'

const { Option } = Select

const ModalAddUsers = ({ plans, users }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    user_id: '',
    subscription_id: state.subscription_id,
  })

  const handleSubmit = () => {
    if (
      formValidator([
        ...document.forms['userPlan-form'].getElementsByTagName('input'),
        ...document.forms['userPlan-form'].getElementsByTagName('select'),
      ])
    ) {
      setLoading(true)
      dispatch(
        attachUserToSub({
          ...formData,
          subscription_id: state.financeData.plan.id,
        }),
      )
        .then((reponse) => {
          history.goBack()
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  return (
    <Container>
      <Modal
        showModal={true}
        modalTitle={`Add User to ${state?.financeData?.user?.name} subscription`}
      >
        <form
          noValidate
          name="userPlan-form"
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
          }}
        >
          <div className="component-container">
            <div>
              <p className="plan-label">
                <span>Plan Name</span>
                <br />
                {state?.financeData?.plan?.name}
              </p>
              <p className="plan-label">
                <span>Plan Description</span>
                <br />
                {state?.financeData?.plan?.description}
              </p>
            </div>
            <InputGroup>
              <label>Pick a User</label>
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Please User"
                onChange={(item) => {
                  setFormData((s) => ({ ...s, user_id: item }))
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {users &&
                  users.data &&
                  users.data.map((item, index) => (
                    <Option key={`${item.name}-${index}`} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </InputGroup>
          </div>
          <footer>
            <Button loading={loading} full type="submit">
              Submit
            </Button>
          </footer>
        </form>
      </Modal>
    </Container>
  )
}

export default ModalAddUsers
