import React, { useEffect } from "react";
import { IoMdAdd } from "react-icons/io";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../../hooks";
import { getAllSubAdmins } from "../../../redux/actions";
import { dashboardRoute } from "../../../constants";
import { TableContainer, Button } from "../../../UI";
import { columns } from "./tableData";
import ModalContent from "./ModalContent";
import Container from "./style";

const ManageUser = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { subAdmins } = useSelector((state) => state.dashStats);
  const modal = useQuery().get("modal");
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllSubAdmins());
  }, [dispatch]);

  return (
    <>
      {modal === "add" && <ModalContent {...{ action: modal, subAdmins }} />}
      <Container>
        <header className="component-header">
          <h1>SubAdmins</h1>
        </header>
        <TableContainer
          pagination={{ pageSize: 7 }}
          dataSource={subAdmins ? subAdmins : []}
          columns={columns({
            handleViewProfile: (row) => {
              history.push(
                `${dashboardRoute.admin.userProfile.link}/${row.id}`
              );
            },
          })}
          {...{
            subAdmins,
            customHeader: (
              <div className="subAdmin-table_header">
                <h1>SubAdmins</h1>
                <Button onClick={() => history.push(`${pathname}?modal=add`)}>
                  <IoMdAdd />
                  Add New
                </Button>
              </div>
            ),
          }}
        />
      </Container>
    </>
  );
};

export default ManageUser;
