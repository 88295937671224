import { message } from "antd";
import { axios, handleError } from "../../lib";
import Cookies from "js-cookie";

export const altAuthState = (data) => ({
  type: "ALT_AUTH_STATE",
  data,
});

export const altLoadingState = (data) => ({
  type: "ALT_LOADING",
  data,
});

export const logout = () => async (dispatch) => {
  Cookies.remove("ecofitness-token");
  dispatch({ type: "CLEAR DATA" });
};

export const getEntireUserData = () => async (dispatch) => {
  try {
    const response = await axios.get(`/admin/users/all`);
    if (response.data) {
      dispatch({
        type: "ALT_ENTIE_USES_DATA",
        data: response.data,
      });
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const fetchDashboardData = () => async (dispatch) => {
  const dashStats = await axios.get("/admin/dashboard");
  if (dashStats) {
    let { responseCode, ...rest } = dashStats;
    dispatch({ type: "ALT_DASH_STATS", data: rest });
  }
};

export const forgotPassword = async ({ email }) => {
  return await axios.post("/auth/forgot-password", { email });
};

export const logIn = (authData) => async (dispatch) => {
  try {
    const { status, token, details } = await axios.post("/auth/auth", authData);
    if (status === "success") {
      if (token) Cookies.set("ecofitness-token", token);
      const response = await axios.get("/auth/user");
      if (response.data) {
        dispatch({
          type: "ALT_AUTHENTICATE_USE_DATA",
          data: response.data,
        });
      }
      dispatch(altAuthState(true));
      message.success(details, 8);
      return {
        success: true,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const getLocations = () => async (dispatch) => {
  try {
    const { status, data: response } = await axios.get("/app/location");
    if (status === "success") {
      dispatch({
        type: "ALT_LOCATION",
        data: response,
      });
    }
  } catch ({ response }) {
    handleError(response);
  }
};
