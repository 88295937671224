import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { useQuery } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { TableContainer, Button, InputGroup } from "../../../UI";
import { getAllUsers } from "../../../redux/actions";
import { dashboardRoute } from "../../../constants";
import { columns } from "./tableData";
import ModalContent from "./ModalContent";
import Container from "./style";

const initFilterState = {
  userType: "",
  gender: "",
  location_id: "",
};

const ManageUser = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const modal = useQuery().get("modal");

  const [{ userType, location_id, gender }, setFilterState] =
    useState(initFilterState);

  const { users, gymLocations } = useSelector((state) => state.dashStats);
  const history = useHistory();
  const [{ activeIndex, loading, currentPage }, setState] = useState({
    activeIndex: null,
    loading: false,
    currentPage: 1,
  });

  useEffect(() => {
    if (loading && currentPage !== users.meta.current_page) {
      setState((s) => ({
        ...s,
        loading: false,
        currentPage: users.meta.current_page,
      }));
    }
  }, [users, loading, currentPage]);

  const handleQueryFetch = ({ page, search } = {}) => {
    let queries = [];

    if (userType) {
      queries.push(`status=${userType}`);
    }

    if (gender) {
      queries.push(`gender=${gender}`);
    }

    if (location_id !== "") {
      queries.push(`location_id=${location_id}`);
    }

    if (page) {
      queries.push(`page=${page}`);
    }

    if (search) {
      queries.push(`search=${search}`);
    }

    setState((s) => ({
      ...s,
      loading: true,
    }));

    dispatch(
      getAllUsers(queries.length > 0 ? `?${queries.join("&")}` : null)
    ).finally(() => {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    });
  };

  useEffect(() => {
    handleQueryFetch();
  }, [userType, location_id, gender]);

  const actionHandler = (index) => {
    setState((s) => ({ ...s, activeIndex: index }));
  };

  const clickHandler = useCallback(() => {
    if (activeIndex || activeIndex === 0) {
      setState((s) => ({ ...s, activeIndex: null }));
    }
  }, [activeIndex]);

  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  });

  const handleInput = ({ target: { name, value } }) => {
    setFilterState((s) => ({
      ...s,
      [name]: value,
    }));
  };

  const isActive = userType || gender || location_id;

  return (
    <Container>
      {modal === "addUser" && <ModalContent {...{ action: modal }} />}
      <header className="component-header">
        <h1>All Users</h1>
        <div className="header-row">
          <div className="action-group">
            <InputGroup
              type="select"
              value={gender}
              disabled={loading}
              name="gender"
              onChange={handleInput}
              optionLists={
                <>
                  <option value="" disabled>
                    Select gender...
                  </option>
                  {["M", "F"].map((d) => (
                    <option value={d} key={d}>
                      {d === "M" ? "Male" : "Female"}
                    </option>
                  ))}
                </>
              }
            />
            <InputGroup
              type="select"
              name="userType"
              value={userType}
              disabled={loading}
              onChange={handleInput}
              optionLists={
                <>
                  <option value="" disabled>
                    Select Users...
                  </option>
                  {["Active", "In-Active"].map((d) => (
                    <option value={d} key={d}>
                      {d}
                    </option>
                  ))}
                </>
              }
            />
            <InputGroup
              type="select"
              name="location_id"
              value={location_id}
              disabled={loading}
              onChange={handleInput}
              optionLists={
                <>
                  <option value="" disabled>
                    Select Location...
                  </option>
                  {Object.keys(gymLocations).map((locationId) => (
                    <option value={locationId} key={locationId}>
                      {gymLocations[locationId]}
                    </option>
                  ))}
                </>
              }
            />

            <Button
              style={{
                visibility: isActive ? "visible" : "hidden",
                pointerEvent: isActive ? "auto" : "none",
              }}
              onClick={() => {
                setFilterState(initFilterState);
                dispatch(getAllUsers());
              }}
            >
              Clear Filters
            </Button>
          </div>
          <div className="lastCol">
            <Button onClick={() => history.push(`${pathname}?modal=addUser`)}>
              <IoMdAdd />
              Add New
            </Button>
          </div>
        </div>
      </header>
      <TableContainer
        {...{
          columns: columns({
            gymLocations,
            handleViewProfile: (row) => {
              history.push(
                `${dashboardRoute.admin.userProfile.link}/${row.id}`
              );
            },
            handleEditProfile: (row) => {
              history.push(
                `${dashboardRoute.admin.userProfile.link}?modal=addUser&userId=${row.id}`
              );
            },
            handleViewOrder: (row) => {
              history.push(
                `${dashboardRoute.admin.userProfile.link}/${row.id}/orders`
              );
            },
            activeIndex,
            actionHandler,
            viewHandler: ({ id }) => {
              history.push(`/admin/dashboard/creators/${id}/details`);
            },
          }),
          searchHandler: (searchVal) => {
            handleQueryFetch({ search: searchVal });
          },

          dataSource: users.data,
          activeIndex,
          loading: loading,
          pagination: {
            pageSize: users?.meta?.per_page,
            current: users?.meta?.current_page,
            total: users?.meta?.total,
            onChange: (page) => {
              handleQueryFetch({ page });
            },
          },
        }}
      />
      {/* </Tabs.TabPane> */}
      {/* <Tabs.TabPane tab="Orders" key="2">
          <header className="component-header">
            <h1>All Orders</h1>
          </header>
          <TableContainer
            {...{
              columns: orderColumns({
                handleViewProfile: (row) => {
                  history.push(
                    `${dashboardRoute.admin.userProfile.link}/${row.id}`
                  );
                },
                handleViewOrder: (row) => {
                  history.push(
                    `${dashboardRoute.admin.userProfile.link}/${row.id}/orders`
                  );
                },
                activeIndex,
                actionHandler,
                viewHandler: ({ id }) => {
                  history.push(`/admin/dashboard/creators/${id}/details`);
                },
              }),
              searchHandler: (searchVal) => {
                handleQueryFetch({ search: searchVal });
              },

              dataSource: users.data,
              activeIndex,
              loading: loading,
              pagination: {
                pageSize: users?.meta?.per_page,
                current: users?.meta?.current_page,
                total: users?.meta?.total,
                onChange: (page) => {
                  handleQueryFetch({ page });
                },
              },
            }}
          />
        </Tabs.TabPane>
      </Tabs> */}
    </Container>
  );
};

export default ManageUser;
