import Styled from 'styled-components'

export default Styled.div`
    box-shadow: 0px 3px 32px #272D3B33;
    border-radius: 20px;
    padding-bottom: 1em;
    background: #fff;
    h1 {
        padding: 0.5em 1.5em;
        padding-top: 1.5em;
        font-weight: 700;
        font-size: 1.1rem;
        color: ${({ theme }) => theme.primary};
    }
    div.info-container {
        padding-left: 1.5em;
        padding-bottom: 1em;
        div {
            display: grid;
            grid-template-columns: 10rem 1fr;
            align-items: center;
            justify-content: space-between;
            &:not(:last-of-type) {
                border-bottom: 1px solid #E6E7E8;
            }
            padding-top: 1.5em;
            p {
                &:first-of-type {
                    color: #A2ABB9;
                    font-size: 0.9rem;
                }
                &:last-of-type {
                    font-weight: 700;
                    color: ${({ theme }) => theme.primary};
                    font-size: 1rem;
                }
            }
        }
    }
`
