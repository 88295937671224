import { message } from "antd";
import { axios, handleError } from "../../lib";

export const altTrainers = (data) => ({
  type: "ALT_TRAINERS",
  data,
});

export const searchTrainers = async (qrCode) => {
  return await axios.get(`/admin/users/user-details-qr-search/${qrCode}`);
};

export const checkInTrainers = (data) => async (dispatch, getState) => {
  try {
    const { responseCode, ...response } = await axios.post(
      `/admin/users/check-in-user`,
      data
    );
    await dispatch(getAllTrainers());
    if (response.status === "success") {
      message.success(response.details, 8);
    } else {
      message.error(response.details, 8);
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const getAllTrainers =
  (queries = "") =>
  async (dispatch) => {
    try {
      let url = `/admin/trainer/all`;

      if (queries) {
        url += queries;
      }

      const { responseCode, ...response } = await axios.get(url);

      if (response.data) {
        dispatch(altTrainers(response.data));
      }
    } catch ({ response }) {
      handleError(response);
    }
  };

export const deleteTrainer = (id) => async (dispatch) => {
  try {
    const { responseCode, ...response } = await axios.delete(
      `/admin/trainer/delete/${id}`
    );

    if (response.status === "success") {
      await dispatch(getAllTrainers());
      message.success(response.message);
    }
  } catch ({ response }) {
    handleError(response);
  }
};
export const altTrainer = (payload) => async (dispatch) => {
  try {
    let url = `/admin/trainer/add-or-edit-trainer`;

    const { responseCode, ...response } = await axios.post(url, payload);

    if (response.data) {
      await dispatch(getAllTrainers());
      message.success(response.message);
    }
    return response;
  } catch ({ response }) {
    handleError(response);
  }
};

export const getTrainerSubscribedUsers = async (trainerId) => {
  try {
    const { responseCode, ...response } = await axios.get(
      `/admin/trainer/users-subscribed-to-trainer/${trainerId}`
    );
    return response;
  } catch ({ response }) {
    handleError(response);
  }
};

export const debitUserAccount = async (payload) => {
  try {
    const { responseCode, ...response } = await axios.post(
      `/admin/users/debit-wallet`,
      payload
    );
    return response;
  } catch ({ response }) {
    handleError(response);
  }
};

export const getUser = async (id) => {
  try {
    const { responseCode, ...response } = await axios.get(
      `/admin/users/user-details/${id}`
    );
    return response;
  } catch ({ response }) {
    handleError(response);
  }
};

export const banTrainers = (id, name) => async (dispatch, getState) => {
  try {
    const response = await axios.post(`/admin/users/ban/${id}`, { name });

    if (response.status === "success") {
      await dispatch(getAllTrainers(response.data));
      message.success(response.details, 8);
      return {
        success: true,
      };
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const unbanTrainers = (id, name) => async (dispatch, getState) => {
  try {
    const response = await axios.post(`/admin/users/un-ban/${id}`, { name });
    if (response.status === "success") {
      await dispatch(getAllTrainers(response.data));
      message.success(response.details, 8);
      return {
        success: true,
      };
    }
  } catch ({ response }) {
    handleError(response);
  }
};
