import React from "react";
import { IoMdMore } from "react-icons/io";
import { Button, Spinner } from "../../../UI";

export const columns = ({
  apiLoading,
  gymLocations,
  handleViewProfile,
  handleDeleteTrainer,
  actionHandler,
  handleEditProfile,
  activeIndex,
}) => [
  {
    title: "Name",
    render: (row) => row.trainer_details.name,
  },
  {
    title: "Gender",
    render: ({ trainer_details }) => {
      return trainer_details.gender
        ? trainer_details.gender.toLowerCase() === "m"
          ? "Male"
          : "Female"
        : null;
    },
  },
  // {
  //   title: "Email",
  //   dataIndex: "email",
  //   sorter: (a, b) => a.email - b.email,
  //   key: "email",
  // },
  // {
  //   title: "Phone No",
  //   dataIndex: "phone",
  //   key: "phone",
  // },
  {
    title: "Location",
    render: (row) => gymLocations[Number(row.location_id)],
  },
  // {
  //   title: "Status",
  //   align: "center",
  //   dataIndex: "status",
  //   key: "status",
  // },
  // {
  //   title: "Total CheckIns",
  //   align: "center",
  //   dataIndex: "total_check_ins",
  //   sorter: (a, b) => a.total_check_ins - b.total_check_ins,
  //   key: "total_check_ins",
  // },
  {
    title: "Action",
    align: "center",
    render: (row) =>
      apiLoading.includes(row.id) ? (
        <Spinner />
      ) : (
        <div className="action-container">
          <div className="table-btn">
            <Button
              icon
              onClick={(e) => {
                e.stopPropagation();
                actionHandler(row.id);
              }}
            >
              <IoMdMore />
            </Button>
          </div>
          {row.id === activeIndex && (
            <div className="context-menu">
              <Button
                secondary
                className="table-btn"
                onClick={() => handleEditProfile(row)}
              >
                Edit Profile
              </Button>
              <Button
                secondary
                className="table-btn"
                onClick={() => handleViewProfile(row)}
              >
                View Profile
              </Button>

              <Button
                secondary
                className="table-btn btn-delete"
                onClick={() => handleDeleteTrainer(row)}
              >
                Delete Trainer
              </Button>
            </div>
          )}
        </div>
      ),
  },
];
