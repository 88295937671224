import React from "react";
import { Tabs } from "antd";
import Container from "./style";

const FinancialReport = () => {
  return (
    <Container>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Financial Report" key="1">
          <div style={{ height: "80vh" }}>
            <iframe
              title="report"
              src="https://report.ecofitnesshub.com/public/dashboard/9e087050-773c-40d4-8b57-1998678d1ffa"
              frameborder="0"
              width="100%"
              height="100%"
              allowtransparency
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="User Wallet" key="2">
          <div style={{ height: "80vh" }}>
            <iframe
              title="report"
              src="https://report.ecofitnesshub.com/public/dashboard/1f78a5cc-f5f6-4ef2-9281-0f1b8ec9c1ea"
              frameborder="0"
              width="100%"
              height="100%"
              allowtransparency
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="User Report" key="3">
          <div style={{ height: "80vh" }}>
            <iframe
              title="report"
              src="https://report.ecofitnesshub.com/public/dashboard/b16488e1-f407-43fc-8030-eb8868a97fcf"
              frameborder="0"
              width="100%"
              height="100%"
              allowtransparency
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
      {/* <Tabs defaultActiveKey="1" items={items} /> */}
    </Container>
  );
};

export default FinancialReport;
