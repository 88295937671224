import { axios, handleError } from "../../lib";
import { message } from "antd";

export const altPlans = (data, actionType) => ({
  type: "ALT_PLANS",
  data,
  actionType,
});

export const getAllPlans = () => async (dispatch, getState) => {
  try {
    const response = await axios.get("/app/plan/all");
    if (response.data) {
      dispatch(altPlans(response.data || []));
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const deletePlan = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/app/plan/delete/${id}`);
    if (response.status === "success") {
      await dispatch(getAllPlans());
      message.success("Successfully deleted plan", 8);
      return {
        success: true,
      };
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const createPlans =
  ({ id, ...data }) =>
  async (dispatch) => {
    try {
      const response = await axios.post("/admin/plan/create", data);
      if (response.status === "success") {
        await dispatch(getAllPlans());
        message.success(response.details, 8);
        return {
          success: true,
        };
      }
    } catch ({ response }) {
      handleError(response);
    }
  };

export const updatePlans =
  ({ id, ...data }) =>
  async (dispatch, getState) => {
    try {
      const response = await axios.post(`/admin/plan/update/${id}`, data);
      if (response.status === "success") {
        await dispatch(getAllPlans());
        message.success(response.details, 8);
        return {
          success: true,
        };
      }
      // if () {
      //     dispatch(altPlans(data, "update"))
      // }
    } catch ({ response }) {
      handleError(response);
    }
  };
