import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formValidator } from "../../../../helpers";
import { Modal, Button, InputGroup } from "../../../../UI";
import { createPlans, updatePlans } from "../../../../redux/actions";
import Container from "./style";

const ModalContent = ({ action, plans }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { gymLocations } = useSelector((s) => s.dashStats);
  const activePlanData =
    location.state && location.state.id
      ? plans.find((item) => item.id === location.state.id)
      : {};
  const [loading, setLoading] = useState(false);
  const [formData, setFormState] = useState(() =>
    action === "edit"
      ? activePlanData
      : {
          name: "",
          amount: 0,
          id: uuid(),
          interval: "Year",
          description: "",
          duration: 0,
          location_id: 1,
        }
  );

  const handleInput = ({ target: { name, value } }) => {
    setFormState((s) => ({ ...s, [name]: value }));
  };

  const handleSubmit = async () => {
    if (
      formValidator(document.forms["class-form"].getElementsByTagName("input"))
    ) {
      try {
        setLoading(true);
        const response =
          action === "edit"
            ? await dispatch(updatePlans({ ...formData }))
            : await dispatch(createPlans({ ...formData }));
        if (response && response.success === true) {
          history.goBack();
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container>
      <Modal
        showModal={true}
        modalTitle={
          action === "edit" ? `Edit ${activePlanData.name} Plan` : "New Plans"
        }
        modalFooter={
          <Button form="class-form" loading={loading} full type="submit">
            {action === "edit" ? "Save" : "Create Plan"}
          </Button>
        }
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          id="class-form"
          name="class-form"
          noValidate
        >
          <InputGroup
            label="Name"
            name="name"
            required={true}
            onChange={handleInput}
            value={formData.name}
          />
          <InputGroup
            label="Amount"
            type="number"
            name="amount"
            required={true}
            onChange={handleInput}
            value={formData.amount}
          />
          <InputGroup>
            <label>Interval</label>
            <select
              name="interval"
              required={true}
              onChange={handleInput}
              value={formData.interval}
            >
              <option value="Day">Day</option>
              <option value={"Week"}>Week</option>
              <option value={"Month"}>Month</option>
              <option value={"Quarter"}>Quarter</option>
              <option value={"Year"}>Year</option>
            </select>
          </InputGroup>

          <InputGroup>
            <label>Location</label>
            <select
              name="location_id"
              required={true}
              onChange={handleInput}
              value={formData.location_id}
            >
              {Object.keys(gymLocations).map((locationId) => (
                <option value={locationId}>{gymLocations[locationId]}</option>
              ))}
            </select>
          </InputGroup>

          <InputGroup
            label="Duration"
            onChange={handleInput}
            value={formData.duration}
            type="number"
            name="duration"
            required={true}
          />
          <InputGroup>
            <label>Description</label>
            <textarea
              name="description"
              required={true}
              value={formData.description}
              onChange={handleInput}
            />
          </InputGroup>
        </form>
      </Modal>
    </Container>
  );
};

export default ModalContent;
