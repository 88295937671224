import React from 'react'
import { FiArrowUpRight } from 'react-icons/fi'
import { toMoney } from '../../../../helpers'
import Container from './style'

const PalletContent = ({ this_month, today, total }) => {
  return (
    <Container>
      <h2 className="title-txt">Financial Report</h2>
      <div className="pallet-container">
        <div className="pallet-item total-user">
          <div className="first-pallet">
            <h1>{toMoney(total)}</h1>
            <p>Total</p>
          </div>
          <div className="bottom-pallet">
            <p>+288%</p>
            <div className="arrow-right">
              <FiArrowUpRight />
            </div>
          </div>
        </div>

        <div className="pallet-item revenue-item">
          <div className="first-pallet">
            <h1>{toMoney(this_month)}</h1>
            <p>This Month</p>
          </div>
          <div className="bottom-pallet">
            <p>+288%</p>
            <div className="arrow-right">
              <FiArrowUpRight />
            </div>
          </div>
        </div>
        <div className="pallet-item total-user">
          <div className="first-pallet">
            <h1>{toMoney(today)}</h1>
            <p>Today</p>
          </div>
          <div className="bottom-pallet">
            <p>+288%</p>
            <div className="arrow-right">
              <FiArrowUpRight />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default PalletContent
