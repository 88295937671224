import { axios, handleError } from "../../lib";
import { message } from "antd";

export const altClasses = (data, actionType) => ({
  type: "ALT_CLASSES",
  data,
  actionType,
});

export const getAllClasses = () => async (dispatch, getState) => {
  try {
    const response = await axios.get("/app/gym-class/all");
    if (response.data) {
      dispatch(altClasses(response.data));
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const createClasses = (data) => async (dispatch) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  try {
    const response = await axios.post("/admin/gym-class/create", formData);
    if (response.status === "success") {
      await dispatch(getAllClasses());
      message.success(response.details, 8);
      return {
        success: true,
      };
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const updateClasses =
  ({ id, class_slots, allowed_plans, users_reserved, ...data }) =>
  async (dispatch, getState) => {
    try {
      const response = await axios.post(`/admin/gym-class/update/${id}`, {
        ...data,
        slots: data.slots.join(),
        plans: data.plans.join(),
      });
      if (response.status === "success") {
        await dispatch(getAllClasses());
        message.success(response.details, 8);
        return {
          success: true,
        };
      }
    } catch ({ response }) {
      handleError(response);
    }
  };
