import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { useHistory, Link, useLocation } from "react-router-dom";
import { logoPrimary, authIllustration } from "../../asset/images";
import { formValidator, doesRouteMatch } from "../../helpers";
import { dashboardRoute } from "../../constants";
import { logIn, forgotPassword, altLoadingState } from "../../redux/actions";
import { Button, InputGroup } from "../../UI";
import Container from "./styles";

const Auth = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const [formData, setFormState] = useState({ email: "", password: "" });

  const isforgotPassword = doesRouteMatch("forgotPassword", pathname);

  const handleInput = ({ target: { name, value } }) => {
    setFormState((s) => ({
      ...s,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (
      formValidator(document.forms["auth-form"].getElementsByTagName("input"))
    ) {
      if (isforgotPassword) {
        dispatch(altLoadingState(true));

        forgotPassword(formData)
          .then((response) => {
            if (response && response.status === "success") {
              message.success(response.details, 8);
            }
          })
          .finally(() => {
            dispatch(altLoadingState(false));
          });
      } else {
        try {
          dispatch(altLoadingState(true));

          const response = await dispatch(logIn(formData));
          if (response && response.success) {
            history.push(dashboardRoute.admin.home);
          } else {
            dispatch(altLoadingState(false));
          }
        } finally {
          dispatch(altLoadingState(false));
        }
      }
    }
  };

  return (
    <Container>
      <div className="col-1">
        <img src={authIllustration} alt="auth" />
      </div>

      <div className="col-2">
        <header>
          <img src={logoPrimary} alt="Eco Fitness" />
          {!isforgotPassword ? (
            <>
              <h3>Sign In</h3>
              <h1>Welcome Back!</h1>
            </>
          ) : (
            <>
              <h1>Forgot Password</h1>
            </>
          )}
        </header>
        <form
          name="auth-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          noValidate
        >
          <InputGroup
            label="Email Address"
            type="email"
            value={formData.email}
            name="email"
            required={true}
            onChange={handleInput}
          />
          {!isforgotPassword && (
            <>
              <InputGroup
                label="Password"
                name="password"
                componentType="password"
                type="password"
                value={formData.password}
                onChange={handleInput}
              />
              <div className="link-container">
                <Link to="/auth/forgotPassword">Forgot Password?</Link>
              </div>
            </>
          )}
          <Button full className="submit-btn" type="submit">
            {isforgotPassword ? "Submit" : "Sign In"}
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default Auth;
