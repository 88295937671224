import React from 'react'
import { toMoney } from '../../../helpers'

export const columns = ({ handleAddUser }) => [
  {
    title: 'Name',
    render: (item) => item.user.name,
  },
  {
    title: 'Plan',
    render: (item) => item.plan.name,
  },
  {
    title: 'Amount',
    render: (item) => toMoney(item.plan.amount),
  },
  {
    title: 'Start Date',
    render: (item) => item.start_date,
  },
  {
    title: 'End Date',
    render: (item) => item.end_date,
  },
  {
    title: 'Action',
    render: (item) => (
      <button className="add-btn" onClick={() => handleAddUser(item)}>
        Add User
      </button>
    ),
  },
]
