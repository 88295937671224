import Styled from "styled-components";
import { minQuery, maxQuery } from "../../../../helpers";

export default Styled.div`
    flex: 1;
    background: #fff;
    height: 100%;
    box-shadow: 0px 3px 32px #272D3B33;
    display: flex;
    padding: 1em 0px;
    ${minQuery("lg")} {
        padding: 1em 2.5em;
    }
    div.top-container {
        width: 85%;
        margin: 0 auto;    
        display: flex;
        align-items: center;
        justify-content: flex-end;  
        ${maxQuery("lg")} {
            width: 95%;
        }
        button.toggle-btn {
            ${minQuery("lg")} {
                display: none;
            }
            .icon {
                color: black;
                font-size: 2rem;
            }
        }
    div.action-container {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        button.notification-btn {
            .icon {
                color: #4D4F5C;
                font-size: 1.4rem;
            }
        }   
        div.profile-container {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: 0px 2.5em; 
            div.img-container {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #EBF0F8;
                margin-right: 0.5em;
                width: 2rem;
                height: 2rem;

                svg {
                    font-size: 3rem;
                }
                border-radius: 50%;
            }
            p {
                font-size: 1rem;
                font-weight: 400;
            }
        }
        button.notification-btn {      
            ${maxQuery("lg")} {
                display: none;
            }
        }
        button.log-out {
            display: flex;
            align-items: center;
            color: #4D4F5C;
            font-weight: 400;
            .icon {
                margin-right: 0.25em;
                font-size: 1.6rem;
            }
        }

    }
    }
`;
