const initState = {
  loading: false,
}

const Dashboard = (state = initState, action) => {
  const { type, data } = action
  switch (type) {
    case 'ALT_LOADING':
      return {
        ...state,
        loading: data,
      }
    default:
      return state
  }
}

export default Dashboard
