import * as React from "react";

function SvgHouse(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 30.534"
      {...props}
    >
      <path
        d="M31.7 13.777L16.646.31a.423.423 0 00-.086-.066.891.891 0 00-1.2-.02L.3 13.691a.89.89 0 001.185 1.33l2.712-2.429v15.7a2.24 2.24 0 002.238 2.238h4.752a2.1 2.1 0 002.1-2.1v-7.98a.323.323 0 01.323-.323h4.8a.323.323 0 01.323.323v7.984a2.1 2.1 0 002.1 2.1h4.752a2.544 2.544 0 002.238-2.8V12.677l2.712 2.429a.895.895 0 001.257-.072.906.906 0 00-.092-1.257zm-5.673 4.39v9.55c0 .638-.316 1.027-.461 1.027h-4.747a.323.323 0 01-.323-.323v-7.97a2.1 2.1 0 00-2.1-2.1H13.6a2.1 2.1 0 00-2.1 2.1v7.984a.323.323 0 01-.323.323H6.425a.462.462 0 01-.461-.461V10.999l9.991-8.925 10.077 9.01v7.082z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgHouse;
