import React from 'react'
import { CgGym } from 'react-icons/cg'
import { Timeline } from 'antd'
import { useLocation } from 'react-router-dom'
import { Modal } from '../../../../UI'
import Container from './style'

const ModalUsers = ({ plans }) => {
  const {
    state: { id },
  } = useLocation()
  const { name, users, description } = plans.find((item) => item.id === id)
  return (
    <Container>
      <Modal showModal={true} modalTitle={`${name} Plan Details`}>
        <div className="component-container">
          <div className="plan-detail__container">
            <p>{description}</p>
          </div>
          <div className="user-lists">
            {users &&
              users.map((item) => (
                <Timeline>
                  <p className="label-item">Users Lists</p>
                  <Timeline.Item>
                    <p>{item.name}</p>
                    <p>{item.email}</p>
                    <p>{item.phone}</p>
                    <p>Status: {item.status}</p>
                  </Timeline.Item>
                </Timeline>
              ))}
            {!users ||
              (users.length === 0 && (
                <div className="emptyUserLists-container">
                  <CgGym />
                  <p>You don't have any user signed up Users for this plan</p>
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </Container>
  )
}

export default ModalUsers
