import React, { useState } from "react";
import { Select } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formValidator } from "../../helpers";
import { Modal, Button, InputGroup } from "../../UI";
import { manualSubscribe } from "../../redux/actions";
import Container from "./style";

const { Option } = Select;

const ModalSubscribeUser = ({ plans, users }) => {
  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { entireUserData } = useSelector((s) => s.dashStats);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    user_id: state?.id || "",
    plan_id: "",
    start_date: "",
  });

  const activeUserData =
    state && state.id
      ? entireUserData.filter((item) => item.id === Number(state.id))[0]
      : null;

  const handleInput = ({ target: { name, value } }) => {
    setFormData((s) => ({
      ...s,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (
      formValidator([
        ...document.forms["userPlan-form"].getElementsByTagName("input"),
        ...document.forms["userPlan-form"].getElementsByTagName("select"),
      ])
    ) {
      setLoading(true);
      dispatch(manualSubscribe(formData))
        .then((reponse) => {
          history.goBack();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Container>
      <Modal
        showModal={true}
        modalTitle={
          activeUserData
            ? `Manual Subscribe ${activeUserData.name}`
            : `Manual Subscribe`
        }
      >
        <form
          noValidate
          name="userPlan-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="component-container">
            <InputGroup
              type="select"
              name="plan_id"
              data-label="Plan"
              value={formData.plan_id}
              onChange={handleInput}
              label={"Pick a plan"}
              required
              optionLists={
                <>
                  <option value="" disabled>
                    Select Plan ...
                  </option>
                  {plans &&
                    plans.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </>
              }
            />
            {!activeUserData && (
              <InputGroup>
                <label>Pick a User</label>
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please User"
                  onChange={(item) => {
                    setFormData((s) => ({ ...s, user_id: item }));
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {users &&
                    users.data &&
                    users.data.map((item, index) => (
                      <Option key={`${item.id}-${index}`} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </InputGroup>
            )}
            <InputGroup
              type="date"
              data-label="Start date"
              required
              name="start_date"
              label="Start Date"
              onChange={handleInput}
            />
          </div>
          <footer>
            <Button loading={loading} full type="submit">
              Submit
            </Button>
          </footer>
        </form>
      </Modal>
    </Container>
  );
};

export default ModalSubscribeUser;
