import { message } from "antd";
import { axios, handleError } from "../../lib";

export const altPurchases = (data) => ({
  type: "ALT_PURCHASES",
  data,
});

export const getPurchases =
  (queries = "") =>
  async (dispatch) => {
    try {
      let url = `/admin/inventory/purchase-history`;

      if (queries) {
        url += queries;
      }
      const { responseCode, ...response } = await axios.get(url);

      if (response.data) {
        dispatch(altPurchases(response.data));
      }
    } catch ({ response }) {
      handleError(response);
    }
  };

export const getUserPurshaseHistory = async (userId, queries = "") => {
  try {
    let url = `/admin/inventory/purchase-history/${userId}`;

    if (queries) {
      url += queries;
    }
    const { responseCode, ...response } = await axios.get(url);

    return response.data;
  } catch ({ response }) {
    handleError(response);
  }
};

export const markPurchaseAsReceived = (id) => async (dispatch) => {
  try {
    const response = await axios.post(
      `/admin/inventory/mark-as-received/${id}`
    );
    if (response.status === "success") {
      await dispatch(getPurchases());
      message.success("Successfully marked as received", 8);
      return {
        success: true,
      };
    }
  } catch ({ response }) {
    handleError(response);
  }
};
