import Styled from 'styled-components'

export default Styled.div`
    form {
        display: flex;
        position: relative;
        align-items: center;
        height: ${({ height }) => height};
        button.search-btn {
            position: absolute;
            top: 50%;
            font-size: 1.2rem;
            color: pink;
            padding-top: 0.2em;
            left: 0.5em;
            transform: translateY(-50%);
            .icon {
                color: black!important;
            }
        }
        input {
            background: #F3F5FC;
            border: 1px solid #E4E8F8;
            border-radius: 4px;
            font-size: 0.85rem;
            padding: 0px 2.5em;
            padding-right: 2em;
            width: 100%;
            height: 100%;
            outline: none;
            &::placeholder {
                color: #A3A8BE;
            }
            &:hover, &:active, &:focus {
                outline: none;
                border: 1px solid ${({ theme }) => theme.primary};
            }
        }
        button.downArrow-btn {
            position: absolute;
            top: 50%;
            right: 0.5em;
            transform: translateY(-50%);
            .icon {
                font-size: 1em;
            }
        }
        div.search-lists {
            position: absolute;
            top: 80%;
            right: 0px;
            width: 18rem;
            padding: 0.4em;
            border-radius: 4px;
            background: #FFFFFF;
            border: 1px solid #E1E4F3;
            box-shadow: 2px 4px 20px rgba(52, 87, 220, 0.05);
            a {
                padding: 0.5em 1em;
                display: block;
                &:active, &:focus, &:hover {
                    background: #F7F9FF;
                    border-radius: 4px;
                    p:first-of-type {
                        color: #2A206A; 
                    }
                }
                p:first-of-type {
                    font-size: 1rem;
                    color: #3772FF;   
                    font-weight: 500;
                }
                p:last-of-type {
                    color: #4D4476;
                    font-size: 0.9rem;
                }
            }
        }
    }
`
