import React from "react";
import { toMoney } from "../../../../helpers";
import Container from "./style";

const BasicInfo = ({ activeUser }) => {
  return (
    <Container>
      <h1>Basic Info</h1>
      <hr />
      <div className="info-container">
        <div>
          <p>Name</p>
          <p>{activeUser.name}</p>
        </div>
        <div>
          <p>Email</p>
          <p>{activeUser.email}</p>
        </div>{" "}
        <div>
          <p>Wallet Balance</p>
          <p>{toMoney(activeUser.wallet_balance)}</p>
        </div>{" "}
        <div>
          <p>Gender</p>
          <p>
            {activeUser.gender && activeUser.gender.toLowerCase() === "m"
              ? "Male"
              : "Female"}
          </p>
        </div>
        <div>
          <p>Total Checkins</p>
          <p>{activeUser.total_check_ins}</p>
        </div>
        <div>
          <p>Total Paid</p>
          <p>{toMoney(activeUser?.total_paid)}</p>
        </div>
      </div>
    </Container>
  );
};

export default BasicInfo;
