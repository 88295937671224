import Styled from 'styled-components'

export default Styled.div`
    div.modal-container {
        width: 35rem;
        form {
            padding: 2em;
            margin: 0 auto;
            overflow-y: auto;
            ${({ theme }) => theme.mixins.scrollbar};
            width: 100%;
            label {
                color: #818181;
                font-weight: 400;
            }
            div.allow-plan {
                h3 {
                    color: #818181;
                    font-weight: 400;
                    font-size: 0.95rem;
                }
                margin-bottom: 1em;
                label {
                    /* display: block;
                    user-select: none;
                    cursor: pointer; */
                }
                input {
                    margin-right: 0.5em;
                }
            }
            div.time-input__container {
                margin-bottom: 2em;
                div.ant-picker {
                    width: 100%;
                    input {
                        border: none;
                        width: 100%;
                    }
                }
            }
            div.file-input__container {
                label {
                    display: block;
                }
                input {
                    
                }
            }
        }
    }
`
