import { axios, handleError } from "../../lib";
import { message } from "antd";

export const altSlots = (data, actionType) => ({
  type: "ALT_SLOTS",
  data,
  actionType,
});

export const getAllSlots = () => async (dispatch, getState) => {
  try {
    const response = await axios.get("/admin/gym-slot/all");
    if (response.data) {
      dispatch(altSlots(response.data));
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const createSlots =
  ({ id, ...data }) =>
  async (dispatch) => {
    try {
      const response = await axios.post("/admin/gym-slot/create", data);
      if (response.status === "success") {
        await dispatch(getAllSlots());
        message.success(response.details, 8);
        return {
          success: true,
        };
      }
    } catch ({ response }) {
      handleError(response);
    }
  };

export const updateSlots =
  ({ id, ...data }) =>
  async (dispatch, getState) => {
    try {
      const response = await axios.post(`/admin/gym-slot/update/${id}`, data);
      if (response.status === "success") {
        await dispatch(getAllSlots());
        message.success(response.details, 8);
        return {
          success: true,
        };
      }
    } catch ({ response }) {
      handleError(response);
    }
  };

export const deleteSlots =
  ({ id, ...data }) =>
  async (dispatch, getState) => {
    try {
      // const response = await axios.post(`/admin/gym-slot/update/${id}`, data)
      // if (response.status === 'success') {
      //   await dispatch(getAllSlots())
      //   message.success(response.details)
      //   return {
      //     success: true,
      //   }
      // }
    } catch ({ response }) {
      handleError(response);
    }
  };
