import Styled from 'styled-components'

export default Styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    header.component-header {
        margin-bottom: 1.5em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 { 
            font-size: 1.2rem;
            color: ${({ theme }) => theme.primary};
            font-weight: 600;
        }
        button {
            display: flex;
            align-items: center;
            border-radius: 10px;
            .icon {
                color: #fff;
                margin-right: 0.5em;
                font-size: 1rem;
            }
        }
    }
`
