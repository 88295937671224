import * as React from "react";

function SvgPieChart(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32.007"
      {...props}
    >
      <defs>
        <style>{".pie-chart_svg__a{fill:#fff}"}</style>
      </defs>
      <path
        className="pie-chart_svg__a"
        d="M27.661 16.816h-12.47V4.346a.9.9 0 00-.907-.907 14.284 14.284 0 1014.284 14.284.912.912 0 00-.907-.907zM14.284 30.193a12.47 12.47 0 01-.907-24.906v12.436a.9.9 0 00.907.907H26.72a12.482 12.482 0 01-12.436 11.563z"
      />
      <path
        className="pie-chart_svg__a"
        d="M32 14.261A14.306 14.306 0 0017.693 0a.9.9 0 00-.909.909v13.4a.9.9 0 00.909.909h13.4a.9.9 0 00.909-.909zm-13.4-.855V1.857a12.5 12.5 0 0111.549 11.549H18.601z"
      />
    </svg>
  );
}

export default SvgPieChart;
