import React from "react";
import Container from "./style";
import Spinner from "../Spinner";

const Button = ({
  disabled = false,
  loading = false,
  loadWithLabel = true,
  primary,
  secondary,
  badge,
  tertiary,
  full,
  style,
  small,
  type = "button",
  className,
  rounded,
  plain,
  icon,
  iconRight,
  iconLeft,
  bordered,
  hasShadow,
  onClick,
  children,
  ...rest
}) => {
  const isPrimary = !secondary || !tertiary || !plain || !rounded;
  return (
    <Container
      className={`btn u-typo__btn ${className ? className : ""}`}
      icon={icon ? icon : undefined}
      iconRight={iconRight ? iconRight : undefined}
      iconLeft={iconLeft ? iconLeft : undefined}
      disabled={disabled ? disabled : loading ? true : false}
      primary={primary || isPrimary ? true : undefined}
      secondary={secondary ? secondary : undefined}
      tertiary={tertiary ? tertiary : undefined}
      plain={plain ? plain : undefined}
      rounded={rounded ? rounded : undefined}
      full={full ? full : undefined}
      onClick={onClick}
      loading={loading ? true : undefined}
      type={type}
      style={style}
      {...rest}
    >
      {badge && <span className="badge-container">{badge}</span>}
      {!loading && children}
      {!loadWithLabel && loading && <Spinner />}
      {loadWithLabel && loading && (
        <>
          {children}
          <span className="loading">
            <Spinner />
          </span>
        </>
      )}
    </Container>
  );
};
export default Button;
