import Styled from "styled-components";

export default Styled.div`
    background-color: #fff;
    box-shadow: 0px 0px 2px #00000029;
    border-radius: 10px;
    padding: 1em 2em;
    header {
        display: flex;
        padding-top: 0.5em;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5em;
        div.table-col__1 {
            h1.table-title {
                font-size: 1.2rem;
                font-weight: 600;
            }
        }
        div.center-content {
            display: flex;r
            grid-gap: 2em;
            button {
                display: flex;
                padding: 0.25em 0.5em;
                align-items: center;
                .icon {
                    color: #fff;
                    font-size: 1.2rem;
                }
            }
        }
        div.table-col__2 {
            display: flex;
            grid-gap: 2em;
            div.action-group {
                display: flex;
                grid-gap: 2em;
                button {
                    display: flex;
                    align-items: center;
                    grid-gap: 0.5em;
                    cursor: pointer;

                    color: ${({ theme }) => theme.primary};
                    .icon {
                        margin: 0px;
                        color: ${({ theme }) => theme.primary}!important;
                    }
                }
            }
        }
        div.table-search {
            position: relative;
            display: flex;
            align-items: center;
             button.back-btn {
                 margin-right: 0.5em;
                .icon {
                    font-size: 1.3rem;
                    color: ${({ theme }) => theme.primary}
                }
            }
            button.close-btn {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -0.8em;
                z-index: 1;
                .icon {
                    font-size: 1.3rem;
                }
            }
            div.input-group {
                margin: 0px;
                input {
                    width: 18rem;
                    height: 2.2rem;
                    border-radius: 15px;
                }
            }
        }
    }
    div.action-container {
        position: relative;
        div.menu-container {
            position: absolute;
            background: #fff;
            width: 6rem;
            padding: 0.25em;
            border-radius: 10px;
            transform: translateX(-50%);
            button {
                display: block;
                width: 100%;
                padding: 0.5em 0em;
            }
        }
    }
    div.table-container {
        width: 100%;
        overflow-x: auto;
    }

`;
