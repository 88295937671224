import Styled from 'styled-components'

export default Styled.div`
    div.modal-container {
        width: 35rem;
        padding-bottom: 2em;
        h3 {
            margin-top: 0.5em;
            padding: 1em 2em;
            font-size: 1rem;
        }
        div.slots-lists {
            padding: 2em;
            padding-top: 1em;
            padding-bottom: 1em;
            height: 60vh;
            ${({ theme }) => theme.mixins.scrollbar};
        }
    }
`
