import Styled from "styled-components";

export default Styled.div`
    div.modal-container {
        width: 35rem;
        form {
            padding: 2em;
            margin: 0 auto;
            height: 40rem;
            overflow-y: auto;
            ${({ theme }) => theme.mixins.scrollbar};
            width: 100%;
            div.img-container {
                margin-bottom: 1.5em;
                text-align: center;
                label {
                    height: 8rem;
                    border-radius: 5px;
                    padding-top: 1em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    /* flex-direction: column; */
                    border: 1px dotted ${({ theme }) => theme.primary};
                    
                    svg {
                        font-size: 2.5rem;
                        margin-bottom: 0.25em;
                    }
                    p {
                        font-size: 0.9rem;
                        margin: 0px;
                    }
                    input {
                        visibility: hidden;
                    }
                }
            }
            label {
                color: #818181;
                font-weight: 400;
            }
            div.input-row {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 1.5em;
            }
            div.plan-container {
                h3 {
                    color: #818181;
                    font-weight: 400;
                    font-size: 0.95rem;
                    margin-bottom: 0.5em;
                }
                margin-bottom: 1em;
                div {
                    display: flex;
                    align-items: center;
                    &:not(:last-of-type) {
                        margin-bottom: 0.5em;
                    }
                    input {
                        margin-right: 0.5em;
                    }
                }
            }
            div.time-input__container {
                margin-bottom: 2em;
                div.ant-picker {
                    width: 100%;
                    input {
                        border: none;
                        width: 100%;
                    }
                }
            }
            div.file-input__container {
                label {
                    display: block;
                }
                input {
                    
                }
            }
        }
    }
`;
