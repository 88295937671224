import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import { Tabs } from "antd";
import { IoArrowBack } from "react-icons/io5";
import { RiArrowRightSLine } from "react-icons/ri";
import { dashboardRoute } from "../../../constants";
import { TableContainer } from "../../../UI";
import { getTrainerSubscribedUsers } from "../../../redux/actions";
import Container from "./style";
import { columns } from "./tableData";
import { useSelector } from "react-redux";

const TrainerProfile = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const trainerId = params[dashboardRoute.admin.trainerProfile.params];
  const [receivedLoading, setReceiveLoading] = useState([]);
  const [subscribeTrainerUsers, setSubscribeTrainerUsers] = useState(null);
  const { gymLocations } = useSelector((s) => s.dashStats);

  useEffect(() => {
    if (trainerId) {
      getTrainerSubscribedUsers(trainerId).then((res) => {
        if (res?.status === "success") {
          setSubscribeTrainerUsers(res.data);
        }
      });
    }
  }, [trainerId]);

  // const handleQueryFetch = ({ page, search } = {}) => {
  //   let queries = [];

  //   if (page) {
  //     queries.push(`page=${page}`);
  //   }

  //   if (search) {
  //     queries.push(`inventory_name=${search}`);
  //   }

  //   setSubscribeTrainerUsers(null);
  //   getUserPurshaseHistory(
  //     trainerId,
  //     queries.length > 0 ? `?${queries.join("&")}` : null
  //   ).then((res) => {
  //     setSubscribeTrainerUsers(res);
  //   });
  // };

  // useEffect(() => {
  //   if (trainerId && prevId.current !== trainerId) {
  //     dispatch(altLoadingState(true));
  //     if (trainerId) {
  //       searchUsers(trainerId).then((response) => {
  //         setActiveUser(response.data);
  //         dispatch(altLoadingState(false));
  //       });
  //     } else {
  //       prevId.current = trainerId;
  //       getUser(trainerId).then((response) => {
  //         dispatch(altLoadingState(false));
  //         if (response) {
  //           setActiveUser(response.data);
  //         } else {
  //           setActiveUser("");
  //         }
  //       });
  //     }
  //   }
  // }, [params, prevId, dispatch, trainerId]);

  return (
    <>
      <Container>
        <header>
          <div className="header-row">
            <button
              type="button"
              aria-label="back"
              onClick={() => {
                history.goBack();
              }}
            >
              <IoArrowBack />
            </button>
            <h1 className="u-typo-title">Trainer Profile Page</h1>
          </div>
          <nav>
            <NavLink to={dashboardRoute.admin.trainer}>Manage Trainer</NavLink>
            <RiArrowRightSLine />
            <NavLink
              to={`${dashboardRoute.admin.trainerProfile.link}/${
                params[dashboardRoute.admin.trainerProfile.params]
              }`}
            >
              Trainer Profile Page
            </NavLink>
          </nav>
        </header>

        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Subscribed Users" key="1">
            <TableContainer
              {...{
                hasSearch: false,
                columns: columns({
                  gymLocations,
                  receivedLoading,
                }),
                searchHandler: (searchVal) => {
                  // handleQueryFetch({ search: searchVal });
                },

                dataSource: subscribeTrainerUsers?.data,
                loading: !subscribeTrainerUsers?.data,
                pagination: {
                  pageSize: subscribeTrainerUsers?.per_page,
                  current: subscribeTrainerUsers?.current_page,
                  total: subscribeTrainerUsers?.total,
                  onChange: (page) => {
                    // handleQueryFetch({ page });
                  },
                },
              }}
            />
          </Tabs.TabPane>
        </Tabs>
      </Container>
    </>
  );
};

export default TrainerProfile;
