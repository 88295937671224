import React from 'react'
import { useSelector } from 'react-redux'
import { LoadingLogo } from '../../asset/images'
import Container from './style'

const Loading = () => {
  const { loading } = useSelector((state) => state.temporary)
  return loading ? (
    <Container>
      <div>
        <img src={LoadingLogo} alt="Loading" />
      </div>
    </Container>
  ) : null
}

export default Loading
