import { combineReducers } from 'redux'
import DashStats from './reducers/DashStats'
import Temporary from './reducers/Temporary'

const rootReducer = combineReducers({
  dashStats: DashStats,
  temporary: Temporary,
})

export default rootReducer
