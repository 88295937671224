import React from 'react'
import moment from 'moment'
import Container from './style'

const ReservedSlots = ({ subscriptions, name, reserved_slots, ...rest }) => {
  return (
    <Container>
      <h1>Reserved Slots</h1>
      <hr />

      <div className="subscription-lists">
        {reserved_slots?.data && reserved_slots.data.length > 0 ? (
          reserved_slots.data.map(({ name, time, end_time, status }) => (
            <div className="sub-item">
              <div className="profile-container">
                <div>
                  <h3>Slot - {name}</h3>
                  <p>
                    {moment(time, 'HH:mm:ss').format('hh:mm A')} -{' '}
                    {moment(end_time, 'HH:mm:ss').format('hh:mm A')}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h1 className="empty-placeholder">{name} don't have any slot</h1>
        )}
      </div>
    </Container>
  )
}

export default ReservedSlots
