import React, { useState } from "react";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { formValidator } from "../../../../helpers";
import { Modal, Button, InputGroup } from "../../../../UI";
import { debitUserAccount } from "../../../../redux/actions";
import Container from "./style";

const DebitUserModal = ({ activeUser, handleModalClose }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    amount: "",
    reason: "",
  });

  const handleInput = ({ target: { name, value } }) => {
    setFormData((s) => ({ ...s, [name]: value }));
  };

  const handleSubmit = async () => {
    if (
      formValidator(
        document.forms["subadmin-form"].getElementsByTagName("input")
      )
    ) {
      if (Number(formData.amount) === 0) {
        message.error("Debit amount needs to be greater than 0");
        return;
      }

      try {
        setLoading(true);

        const response = await debitUserAccount({
          ...formData,
          user_id: activeUser.id,
        });

        if (response && response.status === true) {
          message.success(`Successfully debited ${activeUser.name} account`);
          handleModalClose();
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <Container>
      <Modal
        showModal={true}
        modalTitle={`Debit ${activeUser.name} account`}
        onClose={handleModalClose}
        modalFooter={
          <Button form="subadmin-form" full loading={loading} type="submit">
            Debit User
          </Button>
        }
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          id="subadmin-form"
          name="subadmin-form"
          noValidate
        >
          <InputGroup
            label="Reason"
            required={true}
            placeholder="Please state reason for the debit"
            name="reason"
            value={formData.reason}
            onChange={handleInput}
          />
          <InputGroup
            label="Amount"
            type="number"
            required={true}
            name="amount"
            value={formData.amount}
            onChange={handleInput}
          />
        </form>
      </Modal>
    </Container>
  );
};

export default DebitUserModal;
