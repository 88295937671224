import React from "react";
import { IoIosLogOut, IoIosContact } from "react-icons/io";
import { useDispatch } from "react-redux";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { logout } from "../../../../redux/actions";
import { Button } from "../../../../UI";
import Container from "./styles";

const AdminTopNav = ({ image, name, handleToggleNav }) => {
  const dispatch = useDispatch();

  return (
    <Container>
      <div className="top-container">
        <Button
          icon
          aria-label="toggle menu"
          className="toggle-btn"
          onClick={handleToggleNav}
        >
          <HiOutlineMenuAlt2 />
        </Button>
        <div className="action-container">
          <div className="profile-container">
            <div className="img-container">
              <IoIosContact />
            </div>
            <p>{name}</p>
          </div>
          <Button plain className="log-out" onClick={() => dispatch(logout())}>
            <IoIosLogOut />
            Logout
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default AdminTopNav;
