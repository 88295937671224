import { createGlobalStyle } from "styled-components";
import { normalize, lighten } from "polished";
import { maxQuery, minQuery } from "../helpers";

export default createGlobalStyle`
    ${normalize};
    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0px;
        scroll-behavior: smooth;
    }

    * {
      iframe {
        display: none;
      }
    }
      
    html, body {
      font-family: ${({ theme }) => theme.fontFamily};
      padding: 0px;
      margin: 0px;
      font-size: 1rem;
      background: ${({ theme }) => theme.bgColor};    
        font-size: 80%;
      /* ${minQuery("xl")} {
        font-size: 100%;
      } */
      ${minQuery("1500px")} {
        font-size: 90%;
      }
  
    }
    html { 
      overflow-y: overlay;
      overflow-x: hidden;
      body {   
        &.disable-scroll {
          overflow: hidden;
        }
      }
      hr {
        border: none;
        border-bottom: 1px solid #E6E6E6;
      }
      h1, h2, h3, h4, p {
        margin: 0px;
        padding: 0px;
      }
      div.app-content {
        width: 90%;
        padding-top: ${({ theme }) => theme.dimensions.navHeight};
        ${maxQuery("lg")} {
          padding-top: ${({ theme }) => theme.dimensions.navHeightMobile};
        }
        max-width: ${({ theme }) => theme.dimensions.maxWidth};
        margin: 0 auto;

        iframe {
          display: block;
        }
      }
    }


    /* utilities */
    .u-typo-title {
      font-size: 1.25rem;
      font-weight: 600;
    }

    .u-typo__title2 {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .centered-txt {
      text-align: center;
    }

    
      .u-badge-green {
          background: #D3F8EF;
          color: #FF6C00;
      }
       .u-badge-blue {
          background: #CCE7FF;
          color: #2E9BFF;
      }
        .u-badge-yello {
          background: #FFFDCC;
          color: #999400;
      }
      
    button.custom-btn__more {
        padding: 0px;
        font-size: 1.8rem;
        color: #CCCCCC;
    }
    
      ul.ant-pagination {
        padding-right: 2em;
        /* margin: 0px!important;
        margin-top: 2em!important; */
      }
      th.ant-table-cell {
        font-weight: 800!important;
        text-transform: uppercase;
      }
      .ant-pagination-item-active {
          border-color: ${({ theme }) => theme.primary}!important;
        } 
        .ant-pagination-item-active a {
          color: ${({ theme }) => theme.primary}!important;
          &:hover, &:focus {
            color: ${({ theme }) => theme.primary}!important;
            border-color: ${({ theme }) => theme.primary}!important;

          }
        }
      
      
    table, div.table-header {
        font-size: 1rem;
        width: 100%;
        min-width: 70rem;
        overflow-x: auto;
    ${({ theme }) => theme.mixins.hideScrollbar};
    }
        div.action-container {
        position: relative;
        div.context-menu {
            position: absolute;
            z-index: 999;
            width: 10rem;
            top: -0.5em;
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
            border-radius: 2px;
            button {
                display: block;
                border: none!important;
            }
        }
        div.table-btn {
            display: flex;
            align-items: center;
            button {
                display: flex;
                padding: 0px;
                align-items: center;
                justify-content: center;
                &:first-of-type {
                    color: ${({ theme }) => theme.primary};
                    font-weight: 600;
                    margin-right: 1em;
                    padding: 0.5em;
                    font-size: 0.8rem;
                    &:focus, &:hover {
                        outline: none;
                        color: ${({ theme }) =>
                          lighten(0.1, theme.primary)}!important;
                    }
                }
                &:last-of-type {
                    padding: 0 0.2em;
                    .icon {
                        font-size: 1.5rem;
                        color: #666666;
                    }
                }
            }
        }
    }


`;
