import { message } from "antd";
import { axios, handleError } from "../../lib";

export const altInventorys = (data) => ({
  type: "ALT_INVENTORIES",
  data,
});

export const searchInventorys = async (qrCode) => {
  return await axios.get(`/admin/users/user-details-qr-search/${qrCode}`);
};

export const checkInInventorys = (data) => async (dispatch, getState) => {
  try {
    const { responseCode, ...response } = await axios.post(
      `/admin/users/check-in-user`,
      data
    );
    await dispatch(getInventories());
    if (response.status === "success") {
      message.success(response.details, 8);
    } else {
      message.error(response.details, 8);
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const getInventories =
  (queries = "") =>
  async (dispatch) => {
    try {
      let url = `/admin/inventory/all-inventory`;

      if (queries) {
        url += queries;
      }
      const { responseCode, ...response } = await axios.get(url);

      if (response.data) {
        dispatch(altInventorys(response.data));
      }
    } catch ({ response }) {
      handleError(response);
    }
  };

export const getInventory = async () => {
  try {
    const { responseCode, ...response } = await axios.get(
      `/admin/inventory/all-inventory`
    );
    return response;
  } catch ({ response }) {
    handleError(response);
  }
};

export const createInventory = (data) => async (dispatch) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  try {
    const response = await axios.post(
      "/admin/inventory/add-inventory",
      formData
    );
    if (response.status === "success") {
      await dispatch(getInventories());
      message.success("Successfully Added Inventory", 8);
      return {
        success: true,
      };
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const updateInventory =
  ({ id, image, quantity, item, location_id, price }) =>
  async (dispatch) => {
    const payload = { quantity, item, location_id, price };
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    try {
      const response = await axios.post(
        `/admin/inventory/update-inventory/${id}`,
        formData
      );
      if (response.status === "success") {
        await dispatch(getInventories());
        message.success(response.details, 8);
        return {
          success: true,
        };
      }
    } catch ({ response }) {
      handleError(response);
    }
  };

export const deleteInventory = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `/admin/inventory/delete-inventory/${id}`
    );
    if (response.status === "success") {
      await dispatch(getInventories());
      message.success("Successfully deleted item", 8);
      return {
        success: true,
      };
    }
  } catch ({ response }) {
    handleError(response);
  }
};
