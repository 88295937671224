import Styled from 'styled-components'

export default Styled.div`
    div.modal-container {
        width: 38rem;
        form {
            padding: 2em;
            margin: 0 auto;
            height: 40rem;
            overflow-y: auto;
            ${({ theme }) => theme.mixins.scrollbar};
            width: 100%;
            div.time-input__container {
                margin-bottom: 2em;
                div.ant-picker {
                    width: 100%;
                    input {
                        border: none;
                        width: 100%;
                    }
                }
            }
        }
    }
`
