import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableContainer, Button, InputGroup } from "../../../../UI";
import {
  getPurchases,
  markPurchaseAsReceived,
} from "../../../../redux/actions";
import { RECEIVED } from "../../../../constants";
import { useQuery } from "../../../../hooks";
import { columns } from "./tableData";
import AltInventory from "./AltInventory";
import Container from "./style";

const initFilterState = {
  userType: "",
  gender: "",
  location_id: "",
  received: "",
};

const Purchase = () => {
  const modal = useQuery().get("modal");
  const inventoryId = useQuery().get("inventoryId");
  const dispatch = useDispatch();
  const [receivedLoading, setReceiveLoading] = useState([]);
  const [{ userType, location_id, received, gender }, setFilterState] =
    useState(initFilterState);
  const { purchases, gymLocations } = useSelector((state) => state.dashStats);
  const [{ activeId, loading, currentPage }, setState] = useState({
    activeId: null,
    loading: false,
    currentPage: 1,
  });

  console.log({ purchases });
  useEffect(() => {
    dispatch(getPurchases());
  }, [dispatch]);

  useEffect(() => {
    if (loading && purchases && currentPage !== purchases?.current_page) {
      setState((s) => ({
        ...s,
        loading: false,
        currentPage: purchases.current_page,
      }));
    }
  }, [purchases, loading, currentPage]);

  const handleQueryFetch = ({ page, search } = {}) => {
    let queries = [];

    if (userType) {
      queries.push(`status=${userType}`);
    }

    if (gender) {
      queries.push(`gender=${gender}`);
    }

    if (location_id !== "") {
      queries.push(`location_id=${location_id}`);
    }

    if (received !== "") {
      queries.push(`received=${received}`);
    }

    if (page) {
      queries.push(`page=${page}`);
    }

    if (search) {
      queries.push(`q=${search}`);
    }

    setState((s) => ({
      ...s,
      loading: true,
    }));

    dispatch(
      getPurchases(queries.length > 0 ? `?${queries.join("&")}` : null)
    ).finally(() => {
      setState((s) => ({
        ...s,
        loading: false,
      }));
    });
  };

  useEffect(() => {
    handleQueryFetch();
  }, [userType, location_id, received, gender]);

  const actionHandler = (row) => {
    setState((s) => ({ ...s, activeId: row.id }));
  };

  const clickHandler = useCallback(() => {
    if (activeId || activeId === 0) {
      setState((s) => ({ ...s, activeId: null }));
    }
  }, [activeId]);

  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  });

  const handleInput = ({ target: { name, value } }) => {
    setFilterState((s) => ({
      ...s,
      [name]: value,
    }));
  };

  const isActive = userType || gender || location_id;

  const selectedInventory = useMemo(() => {
    return purchases?.data
      ? purchases.data.find(({ id }) => Number(id) === Number(inventoryId))
      : null;
  }, [inventoryId, purchases]);

  return (
    <>
      {modal === "alt-inventory" && (
        <AltInventory {...{ inventoryId, selectedInventory }} />
      )}
      <Container>
        <header className="component-header">
          <h1>Inventories</h1>
          <div className="header-row">
            <div className="action-group">
              <InputGroup
                type="select"
                name="location_id"
                value={location_id}
                disabled={loading}
                onChange={handleInput}
                optionLists={
                  <>
                    <option value="" disabled>
                      Select Location...
                    </option>
                    {Object.keys(gymLocations).map((locationId) => (
                      <option value={locationId} key={locationId}>
                        {gymLocations[locationId]}
                      </option>
                    ))}
                  </>
                }
              />
              <InputGroup
                type="select"
                name="received"
                value={received}
                disabled={loading}
                onChange={handleInput}
                optionLists={
                  <>
                    <option value="" disabled>
                      Select delivery state...
                    </option>
                    {Object.keys(RECEIVED).map((id) => (
                      <option value={id} key={id}>
                        {RECEIVED[id]}
                      </option>
                    ))}
                  </>
                }
              />

              <Button
                style={{
                  visibility: isActive ? "visible" : "hidden",
                  pointerEvent: isActive ? "auto" : "none",
                }}
                onClick={() => {
                  setFilterState(initFilterState);
                  dispatch(getPurchases());
                }}
              >
                Clear Filters
              </Button>
            </div>
          </div>
        </header>
        <TableContainer
          {...{
            searchPlaceHolder: "Search for User",
            columns: columns({
              gymLocations,
              receivedLoading,
              markItemAsReceived: (row) => {
                setReceiveLoading((s) => [...s, row.id]);
                dispatch(markPurchaseAsReceived(row.id)).finally(() => {
                  setReceiveLoading((s) => s.filter((i) => i !== row.id));
                });
              },
            }),
            searchHandler: (searchVal) => {
              handleQueryFetch({ search: searchVal });
            },
            dataSource: purchases?.data,
            activeId,
            loading: loading,
            actionHandler,
            pagination: {
              pageSize: purchases?.per_page,
              current: purchases?.current_page,
              total: purchases?.total,
              onChange: (page) => {
                handleQueryFetch({ page });
              },
            },
          }}
        />
      </Container>
    </>
  );
};

export default Purchase;
