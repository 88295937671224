import { message } from 'antd'
import { axios, handleError } from '../../lib'

export const altFinanceData = (data) => ({
  type: 'ALT_FINANCE_DATA',
  data,
})

export const getFinanceData = () => async (dispatch, getState) => {
  try {
    const response = await axios.get('/admin/financial')
    if (response) {
      dispatch(altFinanceData(response))
    }
  } catch ({ response }) {
    handleError(response)
  }
}

export const manualSubscribe = ({ id, ...data }) => async (dispatch) => {
  try {
    const response = await axios.post('/admin/subscription/manual', data)
    if (response.status === 'success') {
      await dispatch(getFinanceData())
      message.success(response.details)
      return {
        success: true,
      }
    }
  } catch ({ response }) {
    handleError(response)
  }
}

export const attachUserToSub = ({ id, ...data }) => async (dispatch) => {
  try {
    const response = await axios.post(
      '/admin/subscription/attach-user-to-subscription',
      data,
    )
    if (response.status === 'success') {
      await dispatch(getFinanceData())
      message.success(response.details)
      return {
        success: true,
      }
    }
  } catch ({ response }) {
    handleError(response)
  }
}
