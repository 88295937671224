import React from "react";
import { IoMdMore } from "react-icons/io";
import { toMoney } from "../../../helpers";
import { Button, Spinner } from "../../../UI";

export const columns = ({
  gymLocations,
  actionHandler,
  handleViewDetails,
  handleEditPlan,
  handlePlanDelete,
  activeIndex,
  loaders,
}) => [
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => a.name - b.name,
    key: "name",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    sorter: (a, b) => a.Amount - b.Amount,
    render: (item) => `₦ ${toMoney(item)}`,
    key: "amount",
  },
  {
    title: "Discription",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Interval",
    dataIndex: "interval",
    align: "center",
    sorter: (a, b) => a.interval - b.interval,
    key: "interval",
  },
  {
    title: "Status",
    align: "center",
    render: (row) => (Number(row.disabled) ? "Disabled" : "Active"),
  },
  {
    title: "Location",
    render: (row) => gymLocations[Number(row.location_id)],
    sorter: (a, b) => a.location_id - b.location_id,
  },
  {
    title: "Action",
    render: (row, detail, index) => (
      <div className="action-container">
        <div className="table-btn">
          <Button secondary onClick={() => handleViewDetails(row)}>
            View
          </Button>
          <Button
            icon
            onClick={(e) => {
              e.stopPropagation();
              actionHandler(row);
            }}
          >
            <IoMdMore />
          </Button>
        </div>
        {row.id === activeIndex &&
          (loaders.includes(row.id) ? (
            <Spinner />
          ) : (
            <div className="context-menu">
              <Button tertiary full onClick={() => handleEditPlan(row)}>
                Edit
              </Button>

              <Button
                className="delete-btn"
                tertiary
                full
                onClick={() => handlePlanDelete(row)}
              >
                Delete
              </Button>
            </div>
          ))}
      </div>
    ),
  },
];
