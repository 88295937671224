import React from "react";
import { Tabs } from "antd";
import Inventory from "./Inventory";
import Purchase from "./Purchase";
import Container from "./style";

const ManageInventory = () => {
  return (
    <Container>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Inventories" key="1">
          <Inventory />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Purchase History" key="2">
          <Purchase />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
};

export default ManageInventory;
