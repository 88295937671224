import React from "react";
import { IoMdMore } from "react-icons/io";

import { Button } from "../../../UI";

export const columns = ({
  gymLocations,
  actionHandler,
  handleViewSlots,
  handleEditClass,
  activeIndex,
}) => [
  {
    title: "Name",
    sorter: (a, b) => a.name - b.name,
    dataIndex: "name",
    render: (item) => <div>{item}</div>,
  },
  {
    title: "Address",
    dataIndex: "location",
    sorter: (a, b) => a.location - b.location,
    key: "location",
  },
  {
    title: "Location",
    align: "center",
    sorter: (a, b) => a.location - b.location,
    render: (row) => gymLocations[Number(row.location_id)],
  },
  {
    title: "Remaining Slots",
    width: "13rem",
    align: "center",
    render: (row) => (
      <div style={{ textAlign: "center", width: "100%" }}>
        {Number(row.capacity) - Number(row.reserved)}
      </div>
    ),
  },
  {
    title: "Capacity",
    dataIndex: "capacity",
    align: "center",
    key: "capacity",
  },
  {
    title: "Action",
    render: (row) => (
      <div className="action-container">
        <div className="table-btn">
          <Button
            secondary
            className="view-btn"
            onClick={() => handleViewSlots(row)}
          >
            View Slots
          </Button>
          <Button
            icon
            onClick={(e) => {
              e.stopPropagation();
              actionHandler(row);
            }}
          >
            <IoMdMore />
          </Button>
        </div>
        {row.id === activeIndex && (
          <div className="context-menu">
            <Button tertiary full onClick={() => handleEditClass(row)}>
              Edit
            </Button>
            {/* <Button tertiary full className="btn-delete">
              Delete
            </Button> */}
          </div>
        )}
      </div>
    ),
  },
];
