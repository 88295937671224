import axios from "axios";
import Cookies from "js-cookie";
import { message } from "antd";

const server = axios.create({
  baseURL: "https://apiv2.ecofitnesshub.com/api/v1/",

  // 'https://api.ecofitnesshub.com/api/v1/',
  // process.env.NODE_ENV === 'development'
  //   ? 'https://gym.traki.ng/api/v1/'
  //   : 'https://api.ecofitnesshub.com/',
  headers: { "Content-Type": "application/json" },
});

server.interceptors.request.use((config) => {
  const token = Cookies.get("ecofitness-token");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

server.interceptors.response.use(
  (response) => {
    if (response.data.responseCode) {
      response.data.responseCode = Number(response.data.responseCode);
    } else {
      response.data.responseCode = Number(response.data.responsecode);
    }

    if (response.data.responsemessage) {
      response.data.responseMessage = response.data.responsemessage;
    }

    return response.data;
  },
  (err) => {
    if ([401, 403].includes(err.response.status)) {
      Cookies.remove("ecofitness-token");
      if (403 === err.response.status) {
        message.warning("Your Session has Expired kindly Login again", 8);
      }

      localStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    // NProgress.done()
    return Promise.reject(err);
  }
);

export default server;
