import { message } from "antd";
import { axios, handleError } from "../../lib";

export const altUsers = (data) => ({
  type: "ALT_USERS",
  data,
});

export const searchUsers = async (qrCode) => {
  return await axios.get(`/admin/users/user-details-qr-search/${qrCode}`);
};

export const checkInUsers = (data) => async (dispatch, getState) => {
  try {
    const { responseCode, ...response } = await axios.post(
      `/admin/users/check-in-user`,
      data
    );
    await dispatch(getAllUsers());
    if (response.status === "success") {
      message.success(response.details, 8);
    } else {
      message.error(response.details, 8);
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const getAllUsers =
  (queries = "") =>
  async (dispatch) => {
    try {
      let url = `/admin/users/all`;

      if (queries) {
        url += queries;
      }

      const { responseCode, ...response } = await axios.get(url);

      if (response.data) {
        dispatch(altUsers(response));
      }
    } catch ({ response }) {
      handleError(response);
    }
  };

export const debitUserAccount = async (payload) => {
  try {
    const { responseCode, ...response } = await axios.post(
      `/admin/users/debit-wallet`,
      payload
    );
    return response;
  } catch ({ response }) {
    handleError(response);
  }
};

export const getUser = async (id) => {
  try {
    const { responseCode, ...response } = await axios.get(
      `/admin/users/user-details/${id}`
    );
    return response;
  } catch ({ response }) {
    handleError(response);
  }
};

export const banUsers = (id, name) => async (dispatch, getState) => {
  try {
    const response = await axios.post(`/admin/users/ban/${id}`, { name });

    if (response.status === "success") {
      await dispatch(getAllUsers(response.data));
      message.success(response.details, 8);
      return {
        success: true,
      };
    }
  } catch ({ response }) {
    handleError(response);
  }
};

export const unbanUsers = (id, name) => async (dispatch, getState) => {
  try {
    const response = await axios.post(`/admin/users/un-ban/${id}`, { name });
    if (response.status === "success") {
      await dispatch(getAllUsers(response.data));
      message.success(response.details, 8);
      return {
        success: true,
      };
    }
  } catch ({ response }) {
    handleError(response);
  }
};
